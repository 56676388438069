<template>
  <div class="lg:mt-20 md:mt-10 lg:mx-24">
    <div class="flex flex-col md:flex-row md:gap-6">
      <div
        class="lg:w-80 md:w-full bg-[#FCFBF7] md:border border-none lg:shadow rounded-lg p-6 hidden lg:inline"
      >
        <UserInfo :user="user" />
        <UserInfoCard />
      </div>
      <div class="lg:w-[70%] md:w-full flex justify-center md:mt-0 lg:h-max">
        <slot></slot>
      </div>
    </div>
    <div>
      <ShippingInfo />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import profileImage from "@/assets/profile.png"; // Import profile image
import UserInfo from "./UserInfo.vue";
import UserInfoCard from "./UserInfoCard.vue";
import ShippingInfo from "./ShippingInfo.vue";

export default defineComponent({
  components: {
    UserInfo,
    UserInfoCard,
    ShippingInfo,
  },
  data() {
    return {
      user: {
        name: "John Doe",
        phone: "+1234567890",
        email: "john.doe@example.com",
        profileImage, // Use imported profile image here
      },
    };
  },
});
</script>

<style>
.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.radius {
  border-radius: 15px 15px 0px 0px;
}
</style>
