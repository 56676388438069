<template>
  <div class="md:mb-4 md:px-8">
    <!-- Header -->
    <h1 class="text-lg font-nunito font-semibold mb-3 lg:leading-5">
      Cashback History
    </h1>

    <!-- Cashback History Cards -->
    <div
      v-for="(transaction, index) in cashbackHistory"
      :key="index"
      class="border border-[#28B14C] rounded-lg p-4 lg:mb-6 mb-3"
    >
      <div class="flex justify-between items-center">
        <!-- Left Side: Date and Order ID -->
        <div>
          <p class="font-nunito md:text-base text-sm font-medium">
            <span class="text-[#B0393F]"> Debited on:</span>
            <span class="text-[#28B14C]"> {{ transaction.date }}</span>
          </p>
          <p class="text-[#6F6F6F] md:text-base text-sm">
            Order Id: {{ transaction.orderId }}
          </p>
        </div>

        <!-- Right Side: Amount -->
        <div
          :class="{
            'text-[#B0393F]': transaction.amount < 0,
            'text-[#B0393F]': transaction.amount >= 0,
          }"
          class="md:text-base text-sm font-normal"
        >
          ₹ - {{ transaction.amount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cashbackHistory: [
        {
          date: "14.08.2024",
          orderId: "645289364827",
          amount: -0, // Example debit
        },
        {
          date: "10.08.2024",
          orderId: "748291364534",
          amount: 50, // Example credited
        },
      ],
    };
  },
};
</script>

<style scoped>
/* You can add any additional custom styling here if needed */
</style>
