<template>
  <div
    class="flex flex-col items-center self-center justify-center h-full md:mt-0 p-4 text-gray-500"
  >
    <div class="md:mt-[2rem] items-center">
      <img
        :src="emptyCartImage"
        alt="Empty Cart"
        class="lg:w-56 lg:h-56 w-40 h-40 mb-4"
      />
      <h2
        class="font-bold text-2xl text-center text-[#111827] font-poppins mb-2"
      >
        Empty Cart
      </h2>
      <p class="text-base font-normal text-center text-[#6B7280] font-poppins">
        Your cart is empty
      </p>
    </div>
  </div>
</template>

<script>
import emptyCartImage from "@/assets/emptyCart.png";

export default {
  name: "EmptyCart",
  data() {
    return {
      emptyCartImage,
    };
  },
};
</script>

<style scoped>
/* Additional styling if needed */
</style>
