<template>
  <div>
    <!-- Left Arrow -->
    <button
      @click="prevSlide"
      class="absolute hidden lg:inline md:top-1/2 top-[11rem] transform -translate-y-1/2 mx-2 md:left-4 left-1 z-10"
    >
      <img
        src="@/assets/leftArrow.png"
        alt="Previous"
        class="w-6 h-6 md:w-8 md:h-8"
      />
    </button>
    <div class="relative mt-10 md:mt-20 lg:mt-20 lg:mx-24 mx-4 overflow-hidden">
      <!-- Slider Images -->
      <div
        class="flex transition-transform duration-[800ms] ease-out"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      >
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image"
          :alt="`Slide ${index + 1}`"
          class="object-cover md:w-full h-44 md:h-auto flex-shrink-0"
        />
      </div>

      <!-- Dots for Slide Indicators -->
      <div class="flex justify-center mt-4 md:mt-5">
        <span
          v-for="(image, index) in images"
          :key="index"
          class="w-2 h-2 md:w-3 md:h-3 rounded-full mx-1 cursor-pointer"
          :class="{
            'bg-[#28B14C]': currentIndex === index,
            'bg-black': currentIndex !== index,
          }"
          @click="goToSlide(index)"
        ></span>
      </div>
    </div>

    <!-- Right Arrow -->
    <button
      @click="nextSlide"
      class="absolute hidden lg:inline md:top-1/2 top-[11rem] transform -translate-y-1/2 md:right-4 right-1 mx-2 z-10"
    >
      <img
        src="@/assets/rightArrow.png"
        alt="Next"
        class="w-6 h-6 md:w-8 md:h-8"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "MainSlider",
  data() {
    return {
      currentIndex: 0,
      images: [
        require("@/assets/slideOne.png"),
        require("@/assets/slideTwo.png"),
        require("@/assets/slideThree.png"),
      ],
    };
  },
  methods: {
    goToSlide(index) {
      this.currentIndex = index;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
  },
  mounted() {
    setInterval(this.nextSlide, 5000);
  },
};
</script>
