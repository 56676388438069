<template>
  <div class="md:my-10 relative mx-10 md:mx-0">
    <div class="flex w-full items-center">
      <!-- Left Arrow -->
      <img
        :src="leftarrowIcon"
        alt="Left Arrow"
        class="arrow md:w-8 md:h-8 w-6 h-6 cursor-pointer absolute md:left-0 -left-[32px] z-10 md:ml-10"
        @click="loadPreviousProducts"
      />

      <div
        class="grid product-grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 flex-1 lg:mx-24 md:mx-4"
      >
        <div
          v-for="product in visibleProducts"
          :key="product.id"
          class="product-card relative"
          :class="{ 'transition-transform duration-500 ease-in-out': sliding }"
        >
          <!-- Video Section -->
          <div
            class="video-section w-full h-56 md:h-96 lg:h-96 bg-gray-200 flex items-center justify-center overflow-hidden relative"
          >
            <!-- Displaying the video for each product -->
            <video
              :src="product.video"
              class="w-full h-full object-cover"
              autoplay
              muted
              loop
            ></video>
          </div>
          <div class="mt-4 text-left px-2 absolute bottom-0 w-full blurred-bg">
            <div class="flex items-center gap-2">
              <button
                class="text-white font-medium text-left text-xs md:text-sm p-1 md:p-1"
                @click="openModal(product)"
              >
                View Product
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Arrow -->
      <img
        :src="arrowIcon"
        alt="Right Arrow"
        class="arrow md:w-8 md:h-8 w-6 h-6 cursor-pointer absolute md:right-0 -right-[32px] lg:mr-10"
        @click="loadMoreProducts"
      />
    </div>

    <ProductModal
      v-if="isModalOpen"
      :product="selectedProduct"
      @close="closeModal"
    />
  </div>
</template>

<script>
import ProductModal from "./ProductModal.vue";
import { products } from "../../productData";
export default {
  components: {
    ProductModal,
  },
  data() {
    return {
      products: products, // Use the imported products array
      visibleProducts: [],
      productsPerPage: 4,
      currentPage: 0,
      sliding: false,
      arrowIcon: require("@/assets/rightArrow.png"),
      leftarrowIcon: require("@/assets/leftArrow.png"),
      isModalOpen: false,
      selectedProduct: null,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.productsPerPage = window.innerWidth < 1024 ? 2 : 4;
      this.loadInitialProducts();
    },
    loadInitialProducts() {
      this.visibleProducts = this.products.slice(
        this.currentPage * this.productsPerPage,
        (this.currentPage + 1) * this.productsPerPage
      );
    },
    loadMoreProducts() {
      this.sliding = true;
      setTimeout(() => {
        this.currentPage =
          (this.currentPage + 1) %
          Math.ceil(this.products.length / this.productsPerPage);
        this.loadInitialProducts();
        this.sliding = false;
      }, 300);
    },
    loadPreviousProducts() {
      this.sliding = true;
      setTimeout(() => {
        this.currentPage =
          (this.currentPage -
            1 +
            Math.ceil(this.products.length / this.productsPerPage)) %
          Math.ceil(this.products.length / this.productsPerPage);
        this.loadInitialProducts();
        this.sliding = false;
      }, 300);
    },
    openModal(product) {
      this.selectedProduct = product;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
.blurred-bg {
  background: rgba(45, 45, 45, 0.4);
  backdrop-filter: blur(10px);
}
</style>
