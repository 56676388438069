<template>
  <div>
    <MainSlider />
    <MainFeature />

    <!-- Product Grid -->
    <div
      class="grid grid-cols-2 item-center md:grid-cols-2 lg:grid-cols-4 gap-4 my-10 lg:gap-3 md:gap-x-5 gap-x-3 gap-y-10 lg:mx-24 mx-4 md:mx-8 md:my-16 cursor-pointer"
    >
      <ProductCard
        v-for="product in visibleProducts"
        :key="product.id"
        :product="product"
        @add-to-cart="handleAddToCart"
        class="cursor-pointer"
      />
    </div>

    <!-- View More / View Less Button -->
    <div class="flex justify-center item-center">
      <button
        @click="toggleProducts"
        class="font-poppins md:text-lg text-xs font-medium border border-[#05AEEF] md:py-3 py-2 md:px-10 px-5 rounded text-[#05AEEF]"
      >
        {{ showAll ? "View Less" : "View all products" }}
      </button>
    </div>

    <OurCustomer />

    <CartSidebar
      v-if="isCartVisible"
      :isCartVisible="isCartVisible"
      :cartItems="cartItems"
      @increase-quantity="increaseQuantity"
      @decrease-quantity="decreaseQuantity"
      @remove-item="removeItem"
      @close-cart="closeCart"
    />
  </div>
</template>

<script>
import MainFeature from "../MainFeature/MainFeature.vue";
import MainSlider from "../Slider/MainSlider.vue";
import { products } from "../../productData";
import ProductCard from "./ProductCard.vue";
import CartSidebar from "../BestsellerProduct/CartSidebar.vue";
import { mapState, mapActions } from "vuex";
import OurCustomer from "../OurCustomer/OurCustomer.vue";

export default {
  name: "AllProducts",
  components: {
    MainSlider,
    MainFeature,
    ProductCard,
    CartSidebar,
    OurCustomer,
  },
  data() {
    return {
      products,
      showAll: false, // Track whether all products are visible
    };
  },
  computed: {
    ...mapState(["cartItems", "isCartVisible"]),

    // Compute the visible products based on the `showAll` state
    visibleProducts() {
      return this.showAll ? this.products : this.products.slice(0, 8);
    },
  },
  methods: {
    ...mapActions([
      "addToCart",
      "increaseQuantity",
      "decreaseQuantity",
      "removeItem",
      "closeCart",
    ]),

    // Toggle between showing all products and the initial 8
    toggleProducts() {
      this.showAll = !this.showAll;
    },

    // Handle adding product to cart and optionally closing the cart
    handleAddToCart(product) {
      this.addToCart(product);
      this.closeCart(); // Optionally close cart after adding
    },
  },
};
</script>
