<template>
  <div class="w-full">
    <!-- Centered Icon Section -->
    <div class="flex justify-center items-start w-52 h-52 lg:w-80 lg:h-72">
      <img
        src="@/assets/blankaddress.png"
        alt="No Address Icon"
        class="w-52 h-52 md:w-80 md:h-72"
      />
    </div>

    <!-- Message Section -->
    <h2
      class="text-xl font-inter md:text-xl text-center text-black font-bold lg:leading-5"
    >
      No Address Available
    </h2>
  </div>
  <div class="flex justify-center md:mt-8 mt-4 w-full">
    <button
      @click="addAddress"
      class="px-6 w-full lg:mb-8 mb-7 lg:py-2 py-2 mx-6 md:mx-6 rounded border border-[#28B14C] bg-[#28B14C] text-white font-medium text-lg"
    >
      + Add Address
    </button>
  </div>
  <AddAddress
    v-if="showAddAddressModal"
    :isVisible="showAddAddressModal"
    @close="showAddAddressModal = false"
  />
</template>

<script>
import AddAddress from "./AddAddress.vue";

export default {
  components: {
    AddAddress,
  },
  data() {
    return {
      showAddAddressModal: false, // Initialize modal visibility state here
    };
  },
  methods: {
    addAddress() {
      this.showAddAddressModal = true; // Trigger modal visibility change
    },
  },
};
</script>
