<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <div
      class="relative bg-white md:w-1/3 w-full h-full md:h-auto p-6 md:rounded-lg shadow-lg text-center"
    >
      <!-- Close button (x) in the top-right corner -->
      <button
        @click="$emit('close')"
        class="absolute top-4 right-4 text-gray-500 hover:text-gray-700 font-bold text-2xl"
        aria-label="Close"
      >
        &times;
      </button>

      <img
        src="@/assets/rewardImage.png"
        alt="Reward Image"
        class="mx-auto mb-4 w-[200px] h-[300px]"
      />
      <h3
        class="md:text-[26px] text-[26px] font-bold text-[#111827] font-nunito mb-4 leading-10"
      >
        Congratulations, you have <br />
        earned ₹100 cashback!
      </h3>
      <p class="text-[#4B5563] mb-6 lg:text-lg tet-sm">
        You can redeem 20% of your cashback <br />
        points on your next purchase.
      </p>
      <button
        @click="$emit('close')"
        class="bg-[#28B14C] text-white font-nunito py-2 px-6 rounded font-semibold"
      >
        Continue Shopping
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RedeemPopup",
};
</script>
