<template>
  <div
    class="border-dashed rounded-lg border-2 md:px-4 md:w-4/5 md:mt-4 md:mb-4 border-[#28B14C] bg-[#F1FCF3] p-3 flex"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      class="mr-2"
    >
      <path
        d="M8.28665 14.1963C8.13197 14.351 7.88072 14.351 7.72603 14.1963L2.87603 9.34633C-1.21022 5.19696 3.95572 0.0825837 8.00634 4.21571C12.0626 0.159458 17.1932 5.28977 13.1367 9.34602L8.28665 14.196V14.1963Z"
        fill="#28B14C"
      />
    </svg>
    <p class="md:text-sm text-xs text-left font-nunito font-medium">
      Order by November 26th for guaranteed
      <span class="font-bold text-[#28B14C]">Free Gifts</span>
    </p>
  </div>
  <hr class="md:my-5 bg-[#C9C9C9] border-0 h-[1px] mt-5 md:mt-0 mb-5 md:mb-0" />
</template>

<script>
export default {
  name: "OrderBy",
};
</script>
