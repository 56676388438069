<template>
  <div class="relative w-full max-w-lg mx-auto md:hidden">
    <div
      class="overflow-hidden rounded-lg shadow-lg relative border border-[#B8B8B8]"
    >
      <div
        class="whitespace-nowrap transition-transform duration-700 ease-in-out"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      >
        <img
          v-for="(image, index) in productImages"
          :key="index"
          :src="image"
          alt="Product image"
          class="inline-block w-full h-auto object-cover"
        />
      </div>

      <!-- <button
        @click="prevSlide"
        class="absolute top-1/2 -translate-y-1/2 left-2 bg-white p-2 rounded-full shadow-lg"
      >
        &lt;
      </button>
      <button
        @click="nextSlide"
        class="absolute top-1/2 -translate-y-1/2 right-2 bg-white p-2 rounded-full shadow-lg"
      >
        &gt;
      </button> -->
    </div>

    <!-- Dots for slide indicators -->
    <div class="flex justify-center mt-4 space-x-2">
      <span
        v-for="(image, index) in productImages"
        :key="index"
        @click="goToSlide(index)"
        class="w-2 h-2 bg-gray-500 rounded-full cursor-pointer"
        :class="{ 'bg-blue-500': index === currentIndex }"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    productImages() {
      // Combines main image and additional images into a single array
      return [this.img, ...this.images];
    },
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.productImages.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.productImages.length) %
        this.productImages.length;
    },
    goToSlide(index) {
      this.currentIndex = index;
    },
    startAutoSlide() {
      this.autoSlide = setInterval(this.nextSlide, 3000); // Change every 3 seconds
    },
    stopAutoSlide() {
      clearInterval(this.autoSlide);
    },
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
};
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>
