<template>
  <div
    class="bg-[#28B14C] my-10 lg:px-24 md:px-8 px-4 py-4 md:py-6 flex flex-col lg:flex-row lg:items-center text-white"
  >
    <!-- First Column -->
    <div class="lg:w-1/2 mb-6 lg:mb-0">
      <h2 class="md:text-base text-sm font-semibold font-nunito">
        Still have questions?
      </h2>
      <p class="mt-2 font-inter md:text-sm text-sm font-light">
        Can't find the answer you're looking for? Please chat with our friendly
        team.
      </p>
    </div>
    <!-- Second Column -->
    <div class="lg:w-1/2 flex lg:justify-end">
      <button
        class="bg-white text-green-600 md:py-2 md:px-6 w-auto p-2 rounded-lg font-semibold font-nunito text-xs flex items-center gap-1"
      >
        <router-link to="/contact-us" class="flex items-center gap-1">
          Get in touch
          <img src="@/assets/wp.png" alt="Icon" class="w-4 h-4" />
        </router-link>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContact",
};
</script>
