<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div
      class="relative w-full h-full sm:h-auto sm:max-w-2xl sm:w-4/5 py-5 px-10 bg-[#FCFBF7] md:rounded-lg font-cabin text-sm leading-5 overflow-y-auto"
    >
      <!-- Close Button -->
      <button
        @click="closeModal"
        class="absolute top-4 right-4 text-gray-700 text-xl font-bold"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M26.0612 23.938C26.343 24.2198 26.5013 24.602 26.5013 25.0005C26.5013 25.399 26.343 25.7812 26.0612 26.063C25.7794 26.3448 25.3972 26.5031 24.9987 26.5031C24.6002 26.5031 24.218 26.3448 23.9362 26.063L15.9999 18.1242L8.0612 26.0605C7.7794 26.3423 7.39721 26.5006 6.9987 26.5006C6.60018 26.5006 6.21799 26.3423 5.9362 26.0605C5.6544 25.7787 5.49609 25.3965 5.49609 24.998C5.49609 24.5995 5.6544 24.2173 5.9362 23.9355L13.8749 15.9992L5.9387 8.06049C5.6569 7.7787 5.49859 7.39651 5.49859 6.99799C5.49859 6.59948 5.6569 6.21729 5.9387 5.93549C6.22049 5.6537 6.60268 5.49539 7.0012 5.49539C7.39971 5.49539 7.7819 5.6537 8.0637 5.93549L15.9999 13.8742L23.9387 5.93424C24.2205 5.65245 24.6027 5.49414 25.0012 5.49414C25.3997 5.49414 25.7819 5.65245 26.0637 5.93424C26.3455 6.21603 26.5038 6.59823 26.5038 6.99674C26.5038 7.39526 26.3455 7.77745 26.0637 8.05924L18.1249 15.9992L26.0612 23.938Z"
            fill="black"
          />
        </svg>
      </button>
      <h2 class="text-2xl font-medium mb-6 font-inter text-left">
        Edit Address
      </h2>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">
            Full Name <span class="text-red-500">*</span>
          </label>
          <input
            type="text"
            class="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">
            Email address <span class="text-red-500">*</span>
          </label>
          <input
            type="email"
            class="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
      </div>

      <!-- Phone and State -->
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">
            Phone <span class="text-red-500">*</span>
          </label>
          <input
            type="text"
            class="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">
            State <span class="text-red-500">*</span>
          </label>
          <input
            type="text"
            class="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
      </div>

      <!-- Address -->
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 mb-2">
          Address <span class="text-red-500">*</span>
        </label>
        <input class="w-full border border-gray-300 rounded-lg p-2" />
      </div>

      <!-- Pin Code, City, and Address Type -->
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">
            Pin Code <span class="text-red-500">*</span>
          </label>
          <input
            type="text"
            class="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">
            City <span class="text-red-500">*</span>
          </label>
          <input
            type="text"
            class="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
        <div>
          <!-- Address Type -->
          <label class="block text-[13px] font-medium text-gray-700 mb-2">
            Select the type of the address <span class="text-red-500">*</span>
          </label>
          <div class="flex space-x-2">
            <button
              v-for="type in addressTypes"
              :key="type"
              :class="[
                'border border-gray-300 rounded-lg py-2 px-2 w-1/3',
                selectedType === type
                  ? 'bg-blue-500 text-white'
                  : 'bg-green-500 text-white',
              ]"
              @click="selectAddressType(type)"
            >
              {{ type }}
            </button>
          </div>
        </div>
      </div>
      <!-- Default Address Checkbox -->
      <div class="mb-4">
        <label class="inline-flex items-center">
          <input type="checkbox" class="form-checkbox text-indigo-600" />
          <span class="ml-2 text-sm font-normal text-gray-700">
            Make it your Default Address
          </span>
        </label>
      </div>
      <!-- Action Buttons -->
      <div class="flex mt-6 w-full justify-center space-x-2">
        <button
          @click="saveChanges"
          class="bg-green-500 font-inter font-semibold lg:text-xl text-white py-3 px-6 rounded-lg w-1/2"
        >
          Save Address
        </button>
        <button
          @click="closeModal"
          class="border border-green-500 font-inter lg:text-xl font-semibold py-3 px-6 rounded-lg w-1/2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      addressTypes: ["Home", "Work", "Others"],
      selectedType: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveChanges() {
      this.closeModal();
    },
    selectAddressType(type) {
      this.selectedType = type;
    },
  },
};
</script>
