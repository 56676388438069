<template>
  <div class="grid grid-cols-1 md:grid-cols-1 lg:gap-6 lg:mt-10">
    <ProfileCard
      :icon="icons.card1"
      :activeIcon="icons.activeCard1"
      title="Order History"
      description="Return, Order, or buy things again"
      @click="redirectTo('OrderPage')"
      :isActive="activeRoute === 'OrderPage'"
    />
    <ProfileCard
      :icon="icons.card2"
      :activeIcon="icons.activeCard2"
      title="Address"
      description="Edit Address for your orders"
      @click="redirectTo('AddressPage')"
      :isActive="activeRoute === 'AddressPage'"
    />
    <ProfileCard
      :icon="icons.card3"
      :activeIcon="icons.activeCard3"
      title="Woo Cash"
      description="Use cash from Woo cash wallet"
      @click="redirectTo('WooCashPage')"
      :isActive="activeRoute === 'WooCashPage'"
    />
    <ProfileCard
      :icon="icons.card4"
      :activeIcon="icons.activeCard4"
      title="Referral"
      description="Refer and Earn <br> Money"
      @click="redirectTo('ReferralPage')"
      :isActive="activeRoute === 'ReferralPage'"
    />
    <ProfileCard
      :icon="icons.card5"
      :activeIcon="icons.activeCard5"
      title="Payment Modes"
      description="Your saved UPIs, cards and more"
      @click="redirectTo('HomePage')"
      :isActive="activeRoute === 'HomePage'"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import card1 from "@/assets/card1.png";
import activeCard1 from "@/assets/card1B.png";
import card2 from "@/assets/card2.png";
import activeCard2 from "@/assets/card2B.png";
import card3 from "@/assets/card3.png";
import activeCard3 from "@/assets/card3B.png";
import card4 from "@/assets/card4.png";
import activeCard4 from "@/assets/card4B.png";
import card5 from "@/assets/card5.png";
import activeCard5 from "@/assets/card5B.png";
import ProfileCard from "./ProfileCard.vue";

export default defineComponent({
  components: { ProfileCard },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const activeRoute = computed(() => route.name);

    const redirectTo = (pageName) => {
      if (pageName && pageName !== route.name) {
        router.push({ name: pageName });
      }
    };

    return {
      icons: {
        card1,
        activeCard1,
        card2,
        activeCard2,
        card3,
        activeCard3,
        card4,
        activeCard4,
        card5,
        activeCard5,
      },
      activeRoute,
      redirectTo,
    };
  },
});
</script>
