<template>
  <div class="">
    <div
      class="flex justify-between items-left cursor-pointer md:p-4 p-3 border rounded-xl bg-[#28B14C]"
      @click="toggleAccordion"
    >
      <h2
        class="md:text-xl text-sm capitalize font-nunito font-semibold text-white"
      >
        {{ title }}
      </h2>
      <svg
        :class="isOpen ? 'transform rotate-180' : 'transform'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="white"
        class="w-6 h-6 transition-transform duration-200"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
    <transition name="fade">
      <div
        v-if="isOpen"
        class="md:p-4 py-4 md:py-0 md:px-4 text-black font-nunito md:text-base text-sm font-medium md:mt-2"
      >
        <div v-html="content"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "GenericAccordion",
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
