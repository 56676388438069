<template>
  <div class="">
    <div
      class="bg-[#FFE696] p-3 mb-6 md:mb-0 rounded-lg w-full text-center flex items-center justify-center font-poppins"
    >
      <img
        src="@/assets/cashback.png"
        alt="Cashback Icon"
        class="w-7 h-7 mr-1"
      />
      <h2 class="lg:text-base text-xs text-gray-600">
        Get <span class="font-bold text-gray-800">₹100 Cashback </span>
        <span class="mt-2 text-gray-600">Upon paying online at checkout.</span>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "CashBack",
};
</script>
