<template>
  <div>
    <!-- Backdrop for modal effect -->
    <div
      v-if="isCartVisible"
      class="fixed inset-0 bg-black bg-opacity-20 z-40 transition-opacity duration-300 ease-in-out"
      @click="closeCart"
    ></div>

    <div
      class="fixed top-0 right-0 w-[400px] h-full bg-white shadow-lg z-50 transition-transform duration-300 ease-in-out"
      :class="{
        '-translate-x-full': !isCartVisible,
        'translate-x-0': isCartVisible,
      }"
    >
      <div class="p-3 flex justify-between items-center bg-[#28B14C]">
        <h3 class="text-xl font-medium text-white">BAG (0)</h3>
        <button
          @click="closeCart"
          class="text-white text-2xl hover:text-red-500"
        >
          x
        </button>
      </div>

      <div
        class="scrollable overflow-y-auto"
        style="max-height: calc(100vh - 64px)"
      >
        <EmptyCart v-if="cartItems.length === 0" />
        <!-- Render CartItem if there are items -->
        <CartItem
          v-for="item in cartItems"
          :key="item.id"
          :item="item"
          @increase-quantity="increaseQuantity"
          @decrease-quantity="decreaseQuantity"
          @remove-item="removeItem"
        />

        <!-- Show PriceDetail only if there are items in the cart -->
        <div v-if="cartItems.length > 0" class="bg-[#F1FCF3]">
          <PriceDetail
            :cart-items="cartItems"
            :totalPrice="calculatedTotalPrice"
          />
        </div>
      </div>

      <!-- Fixed checkout button at the bottom of the cart -->
      <button
        v-if="cartItems.length > 0"
        class="fixed bottom-0 w-full bg-[#28B14C] text-white py-3 md:mt-6 transition"
        @click="checkout"
        style="max-width: 400px"
      >
        <span class="font-poppins font-medium md:text-xl leading-normal">
          Checkout
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import CartItem from "./CartItem.vue";
import EmptyCart from "./EmptyCart.vue";
import PriceDetail from "./PriceDetail.vue";

export default {
  props: {
    isCartVisible: Boolean,
    cartItems: Array,
  },
  components: {
    CartItem,
    PriceDetail,
    EmptyCart,
  },
  computed: {
    calculatedTotalPrice() {
      return this.cartItems.reduce((total, item) => {
        return total + item.price * item.quantity; // Assuming item has a price field
      }, 0);
    },
  },
  methods: {
    increaseQuantity(item) {
      this.$emit("increase-quantity", item);
    },
    decreaseQuantity(item) {
      this.$emit("decrease-quantity", item);
    },
    removeItem(item) {
      this.$emit("remove-item", item);
    },
    closeCart() {
      this.$emit("close-cart"); // Emit event to close the cart
    },
    checkout() {
      // Handle checkout logic here
      console.log("Proceeding to checkout...");
    },
  },
};
</script>

<style scoped>
/* Custom scrollbar styles */
.scrollable {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #28b14c transparent; /* For Firefox */
}

.scrollable::-webkit-scrollbar {
  width: 0.5px;
  height: 0.5px;
}

.scrollable::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #28b14c; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #22a240;
}
</style>
