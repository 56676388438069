<template>
  <div style="background: rgba(217, 217, 217, 0.5)" class="md:mt-10 mt-10">
    <div class="lg:mx-24 mx-4 lg:mt-10 overflow-hidden md:mx-4">
      <div class="mx-4 mt-10">
        <h2
          class="lg:text-3xl font-semibold text-[#1e1e1e] mb-2 lg:mt-8 lg:mb-2"
        >
          Expert Articles
        </h2>
        <p class="text-[#0F1935] lg:text-xl lg:mb-6">
          Read what our experts have to say
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 lg:mb-24">
        <!-- Left Column (First Blog Post) -->
        <div class="cursor-pointer">
          <div class="overflow-hidden lg:w-full">
            <div class="flex items-center justify-center mt-4 md:mt-0">
              <img
                :src="blogs[0].image"
                :alt="blogs[0].title"
                class="md:w-[700px] md:h-[320px] w-[400px] object-cover"
              />
            </div>

            <h3 class="font-semibold md:text-lg text-md mt-2 text-[#1e1e1e]">
              {{ blogs[0].title }}
            </h3>

            <div class="flex gap-4 mt-2 items-center">
              <img :src="blogs[0].author" class="md:h-7 md:w-7 w-7 h-7" />
              <p class="text-sm text-gray-600 md:mr-24">
                {{ blogs[0].authorName }}
              </p>
              <p class="md:text-xs text-xs text-[#2C3A4B] opacity-70">
                {{ daysAgo(blogs[0].datePosted) }}
              </p>
            </div>
          </div>
        </div>

        <!-- Right Column (Other Blog Posts) -->
        <div class="w-full flex flex-col md:flex-col px-4 md:mx-0">
          <div
            v-for="(blog, index) in blogs.slice(1, 6)"
            :key="index"
            class="flex items-start mb-4 cursor-pointer flex-row md:flex-row"
            @click="goToDetail(index + 1)"
          >
            <img
              :src="blog.image"
              :alt="blog.title"
              class="w-40 h-24 object-cover md:mr-6 mr-3"
            />
            <div class="">
              <h3
                class="font-semibold md:text-base text-xs text-[#1e1e1e] my-2 md:my-0"
              >
                {{ blog.title }}
              </h3>
              <div class="flex gap-4 md:mt-2 items-center">
                <img :src="blog.author" class="md:h-7 md:w-7 w-7 h-7" />
                <p
                  class="md:text-xs text-[10px] text-gray-600 font-nunito font-medium lg:mr-24"
                >
                  {{ blog.authorName }}
                </p>
                <p
                  class="md:text-xs text-[10px] font-nunito font-medium text-[#2C3A4B] opacity-70"
                >
                  {{ daysAgo(blog.datePosted) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blogs } from "../blogData";

export default {
  data() {
    return {
      blogs,
    };
  },
  methods: {
    goToDetail(index) {
      this.$router.push({ name: "blog-detail", params: { id: index } });
    },
    daysAgo(date) {
      const postDate = new Date(date);
      const currentDate = new Date();
      const diffInTime = currentDate - postDate;
      const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
      return diffInDays === 0 ? "Today" : `${diffInDays} days ago`;
    },
  },
};
</script>

<style scoped>
/* Add any additional styles as necessary */
</style>
