<template>
  <div id="app">
    <NavHeader v-if="!isLoginPage" />
    <MainNavbar @toggle-cart="toggleCart" />
    <SearchBox class="md:hidden flex mt-5 !md:mt-6" />

    <router-view></router-view>

    <MainFaq v-if="showFaqAndContact" />
    <MainContact v-if="showFaqAndContact" />
    <MainFooter v-if="!isLoginPage" />

    <CartSidebar
      v-if="isCartVisible"
      :isCartVisible="isCartVisible"
      :cartItems="cartItems"
      @increase-quantity="increaseQuantity"
      @decrease-quantity="decreaseQuantity"
      @remove-item="removeItem"
      @close-cart="closeCart"
    />

    <div
      v-if="isCartVisible"
      class="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ease-in-out"
      @click="closeCart"
    ></div>
  </div>
</template>

<script>
import CartSidebar from "./components/BestsellerProduct/CartSidebar.vue";
import { products } from "./productData";
import NavHeader from "./components/Header/NavHeader.vue";
import MainNavbar from "./components/Navbar/MainNavbar.vue";
import MainFooter from "./components/MainFooter/MainFooter.vue";
import MainFaq from "./components/MainFaq/MainFaq.vue";
import MainContact from "./components/MainContact/MainContact.vue";
import SearchBox from "./components/Navbar/SearchBox.vue";

export default {
  components: {
    NavHeader,
    MainNavbar,
    MainFaq,
    MainContact,
    MainFooter,
    CartSidebar,
    SearchBox,
  },
  data() {
    return {
      products,
      cartItems: [],
      isCartVisible: false,
    };
  },
  methods: {
    toggleCart() {
      this.isCartVisible = !this.isCartVisible;
    },
    addToCart(product) {
      const existingItem = this.cartItems.find(
        (item) => item.id === product.id
      );
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        this.cartItems.push({ ...product, quantity: 1 });
      }
      this.isCartVisible = true;
    },
    increaseQuantity(item) {
      item.quantity += 1;
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity -= 1;
      }
    },
    removeItem(item) {
      this.cartItems = this.cartItems.filter(
        (cartItem) => cartItem.id !== item.id
      );
    },
    closeCart() {
      this.isCartVisible = false;
    },
  },
  computed: {
    isLoginPage() {
      return (
        this.$route.name === "LogOne" ||
        this.$route.name === "LogTwo" ||
        this.$route.name === "LogThree"
      );
    },
    showFaqAndContact() {
      return (
        !this.isLoginPage &&
        this.$route.name !== "ContactPage" &&
        this.$route.name !== "AddressPage" &&
        this.$route.name !== "PrivacyPage" &&
        this.$route.name !== "TermOfService" &&
        this.$route.name !== "CookiesPolicy" &&
        this.$route.name !== "AboutPage" &&
        this.$route.name !== "ReturnCancelPage" &&
        this.$route.name !== "BlogDetails" &&
        this.$route.name !== "WooCashPage" &&
        this.$route.name !== "EditAddress" &&
        this.$route.name !== "EditProfilePage" &&
        this.$route.name !== "OrderHistoryPage" &&
        this.$route.name !== "AddressPage" &&
        this.$route.name !== "EditProfile"
      );
    },
  },
};
</script>
