<template>
  <div
    class="min-h-screen lg:py-10 lg:px-0 font-roboto md:mt-16 mt-10 mx-4 md:mx-8 lg:mx-24"
  >
    <h1
      class="lg:text-left text-left lg:text-5xl text-3xl font-bold md:mb-8 mb-6 text-[#28B14C] capitalize"
    >
      Privacy Policy
    </h1>

    <!-- Banner Image -->
    <div class="lg:w-full lg:h-96 mb-8">
      <img
        src="@/assets/slideOne.png"
        alt="Banner Image"
        class="lg:w-full lg:h-full h-auto object-cover"
      />
    </div>

    <!-- Disclaimer and Privacy Sections Loop -->
    <section
      v-for="(section, index) in policySections"
      :key="index"
      class="bg-white rounded-lg md:p-6 mx-auto"
    >
      <h2
        class="md:text-4xl text-2xl sm:leading-[42px] mt-10 lg:mt-0 md:mb-7 text-left md:leading-relaxed font-bold mb-7 capitalize font-nunito text-[#2B3029]"
      >
        {{ section.title }}
      </h2>
      <p
        v-for="(paragraph, i) in section.content"
        :key="i"
        class="text-left lg:text-lg text-xs md:leading-6 font-nunito text-[#4E4E4E] font-normal leading-relaxed mb-4"
      >
        {{ paragraph }}
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyPage",
  data() {
    return {
      policySections: [
        {
          title: "Disclaimer of Warranty / Limitation of Liabilities",
          content: [
            "All Products made available on or through the Site are provided “As Is” and “As Available” without any warranty of any kind, either express, implied, or otherwise, including without limitation, any implied warranties of merchantability, fitness for a particular purpose, or non-infringement.",
            "Be Me, its affiliates, and its staff do not warrant, and hereby disclaim any warranties, either express, implied, or otherwise, with respect to the site, content quality, accuracy, completeness, and adequacy, or that the Product will meet your needs or expectations.",
            "We and our affiliates do not warrant that the Site will operate uninterruptedly and that it is free of viruses or other harmful components.",
            "Except as prohibited by law, you agree that we and our affiliates will not be liable to you for any loss or damages arising out of or relating to these Terms of Use, or your (or any third party) use of or inability to use the Site.",
            "We and our affiliates are not liable for any incidental, consequential, indirect, or punitive damages arising out of your access to, or use of, the Site or the operation of the Site or failure of the Site to operate or any damages or injury caused by, including but not limited to, any error, omission, interruption, defect, delay in operation of transmission, computer virus, or line failure.",
            "In any such event, our total liability to you for all losses, damages, and causes of action (in contract, tort including without limitation, negligence, or otherwise) will not be greater than the amount, if any, paid by you to access the Site. Your acceptance of this limitation of liability is an essential term of this agreement and you acknowledge that we would not grant access to the Site without your agreement to this term.",
          ],
        },
        {
          title: "Intellectual Property Rights",
          content: [
            "We take your privacy seriously and ensure that your data is protected. By using the Site, you agree to our privacy practices described here.",
          ],
        },
        {
          title: "Use of Site",
          content: [
            "You agree not to misuse the Site for any illegal purpose. You agree not to damage the Site and use it for fraudulent or unlawful activity. You agree not to copy, reproduce, publish, or distribute any material of the Site. Furthermore, we do not state that the Product description or the contents of the Site are accurate, complete, reliable, current, or error-free.The Products available on the Site, are for your personal and or professional use only. The Products, or samples thereof, which you may receive from us, shall not be sold or resold for any commercial purposes.We advise you to carefully read the individual terms and conditions concerning the Products. You should always seek the advice of your physician or any other qualified health provider, with any questions you may have regarding your health or a medical condition, including diagnosis and treatment for your specific medical needs. You should never disregard or delay seeking professional medical advice due to information you have obtained from the Site.",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Add specific styles if needed */
</style>
