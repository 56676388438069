<template>
  <div>
    <!-- Overlay for mobile dropdown; shown only when showMenu is true -->
    <div
      v-if="showMenu"
      class="fixed inset-0 bg-black bg-opacity-50 z-40"
      @click="toggleMenu"
    ></div>

    <nav
      :class="[
        'bg-[#fff]',
        navbarFixed ? 'fixed' : 'relative',
        'top-0 w-full z-50 py-3 px-4 lg:py-2 lg:px-24  flex md:px-8 justify-between items-center md:border-b border-[#EOEOEO]',
      ]"
    >
      <div class="flex items-center space-x-4 md:space-x-4 lg:space-x-0">
        <div class="lg:hidden flex items-center" @click="toggleMenu">
          <a class="text-4xl cursor-pointer text-[#28B14C]">
            <img src="../../assets/List.png" class="h-6 w-6" />
          </a>
        </div>
        <span class="flex items-center">
          <router-link to="/">
            <img
              src="@/assets/Wofree.png"
              alt="Logo"
              class="w-[80%] sm:w-[80%] md:w-[90%] lg:w-[80%] xl:w-[90%] md:h-20 h-14 max-w-[200px]"
            />
          </router-link>
        </span>
      </div>

      <div class="lg:flex md:flex-1 justify-evenly items-center md:hidden">
        <ul
          class="cursor-pointer space-x-4 md:space-x-8 lg:gap-14 text-black font-normal text-base hidden md:flex font-poppins"
        >
          <li><a @click="navigateTo('Products')">Products</a></li>
          <li><a @click="navigateTo('AllBlogs')">Blog</a></li>
        </ul>
        <SearchBox class="hidden md:flex" />
      </div>

      <div class="flex items-center space-x-6">
        <div class="flex items-center">
          <!-- <img
            src="@/assets/cart.png"
            alt="Cart"
            class="md:flex w-6 h-6 cursor-pointer"
            @click="openCart"
          /> -->
          <div @click="openCart">
            <!-- <img src="../../assets/cartNav.png" class="h-5 w-5" /> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M8.125 17.375C8.125 17.6222 8.05169 17.8639 7.91434 18.0695C7.77699 18.275 7.58176 18.4352 7.35335 18.5299C7.12495 18.6245 6.87361 18.6492 6.63114 18.601C6.38866 18.5527 6.16593 18.4337 5.99112 18.2589C5.8163 18.0841 5.69725 17.8613 5.64902 17.6189C5.60079 17.3764 5.62554 17.1251 5.72015 16.8966C5.81476 16.6682 5.97498 16.473 6.18054 16.3357C6.3861 16.1983 6.62777 16.125 6.875 16.125C7.20652 16.125 7.52446 16.2567 7.75888 16.4911C7.9933 16.7255 8.125 17.0435 8.125 17.375ZM15 16.125C14.7528 16.125 14.5111 16.1983 14.3055 16.3357C14.1 16.473 13.9398 16.6682 13.8451 16.8966C13.7505 17.1251 13.7258 17.3764 13.774 17.6189C13.8222 17.8613 13.9413 18.0841 14.1161 18.2589C14.2909 18.4337 14.5137 18.5527 14.7561 18.601C14.9986 18.6492 15.2499 18.6245 15.4784 18.5299C15.7068 18.4352 15.902 18.275 16.0393 18.0695C16.1767 17.8639 16.25 17.6222 16.25 17.375C16.25 17.0435 16.1183 16.7255 15.8839 16.4911C15.6495 16.2567 15.3315 16.125 15 16.125ZM18.7273 6.29219L16.7242 13.5016C16.6141 13.8954 16.3785 14.2425 16.0533 14.4904C15.728 14.7383 15.3308 14.8733 14.9219 14.875H7.2C6.78987 14.8748 6.39105 14.7405 6.06437 14.4925C5.7377 14.2446 5.50108 13.8965 5.39062 13.5016L2.65 3.625H1.25C1.08424 3.625 0.925268 3.55915 0.808058 3.44194C0.690848 3.32473 0.625 3.16576 0.625 3C0.625 2.83424 0.690848 2.67527 0.808058 2.55806C0.925268 2.44085 1.08424 2.375 1.25 2.375H3.125C3.26164 2.37497 3.39453 2.41973 3.50331 2.50241C3.6121 2.58509 3.69079 2.70115 3.72734 2.83281L4.46797 5.5H18.125C18.2214 5.49998 18.3164 5.52224 18.4027 5.56504C18.4891 5.60784 18.5643 5.67001 18.6226 5.74671C18.681 5.82341 18.7208 5.91255 18.7389 6.00718C18.7571 6.1018 18.7531 6.19935 18.7273 6.29219ZM17.3023 6.75H4.81563L6.59766 13.1672C6.63421 13.2989 6.7129 13.4149 6.82169 13.4976C6.93047 13.5803 7.06336 13.625 7.2 13.625H14.9219C15.0585 13.625 15.1914 13.5803 15.3002 13.4976C15.409 13.4149 15.4877 13.2989 15.5242 13.1672L17.3023 6.75Z"
                fill="#28B14C"
              />
            </svg>
          </div>
          <span
            class="hidden md:hidden lg:inline font-nunito bg-clip-text px-2 text-black cursor-pointer"
            @click="$emit('toggle-cart')"
          >
            Cart
          </span>
        </div>

        <div class="relative flex items-center">
          <span @click="toggleLog">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M18.0407 17.0627C16.8508 15.0056 15.0172 13.5306 12.8774 12.8314C13.9358 12.2013 14.7582 11.2412 15.2182 10.0984C15.6781 8.95573 15.7503 7.69361 15.4235 6.50592C15.0968 5.31823 14.3892 4.27064 13.4094 3.52402C12.4296 2.7774 11.2318 2.37305 10 2.37305C8.76821 2.37305 7.57044 2.7774 6.59067 3.52402C5.6109 4.27064 4.90331 5.31823 4.57654 6.50592C4.24978 7.69361 4.32193 8.95573 4.78189 10.0984C5.24186 11.2412 6.06422 12.2013 7.12268 12.8314C4.98284 13.5299 3.14925 15.0049 1.9594 17.0627C1.91577 17.1338 1.88683 17.213 1.87429 17.2955C1.86174 17.378 1.86585 17.4622 1.88638 17.5431C1.9069 17.624 1.94341 17.7 1.99377 17.7665C2.04413 17.8331 2.10731 17.8889 2.17958 17.9306C2.25185 17.9724 2.33175 17.9992 2.41457 18.0096C2.49738 18.0199 2.58143 18.0136 2.66176 17.991C2.74209 17.9683 2.81708 17.9298 2.88228 17.8777C2.94749 17.8256 3.00161 17.761 3.04143 17.6877C4.51331 15.1439 7.11487 13.6252 10 13.6252C12.8852 13.6252 15.4867 15.1439 16.9586 17.6877C16.9985 17.761 17.0526 17.8256 17.1178 17.8777C17.183 17.9298 17.258 17.9683 17.3383 17.991C17.4186 18.0136 17.5027 18.0199 17.5855 18.0096C17.6683 17.9992 17.7482 17.9724 17.8205 17.9306C17.8927 17.8889 17.9559 17.8331 18.0063 17.7665C18.0566 17.7 18.0932 17.624 18.1137 17.5431C18.1342 17.4622 18.1383 17.378 18.1258 17.2955C18.1132 17.213 18.0843 17.1338 18.0407 17.0627ZM5.62503 8.00017C5.62503 7.13488 5.88162 6.28902 6.36235 5.56955C6.84308 4.85009 7.52636 4.28933 8.32579 3.9582C9.12522 3.62707 10.0049 3.54043 10.8535 3.70924C11.7022 3.87805 12.4818 4.29473 13.0936 4.90658C13.7055 5.51843 14.1222 6.29799 14.291 7.14665C14.4598 7.99532 14.3731 8.87499 14.042 9.67441C13.7109 10.4738 13.1501 11.1571 12.4306 11.6379C11.7112 12.1186 10.8653 12.3752 10 12.3752C8.84009 12.3739 7.72801 11.9126 6.90781 11.0924C6.0876 10.2722 5.62627 9.16011 5.62503 8.00017Z"
                fill="#28B14C"
              />
            </svg>
          </span>

          <span
            class="hidden md:hidden lg:inline font-nunito bg-clip-text text-black px-2 cursor-pointer"
            @click="openLoginModal"
          >
            Login
          </span>

          <div
            v-if="showDropLogdown"
            class="absolute right-0 top-[calc(100%+10px)] w-48 lg:w-52 bg-white border rounded-lg shadow-lg z-50 text-[#28B14C] font-nunito font-normal md:leading-5 md:text-base"
          >
            <ul class="py-2">
              <li
                v-for="(item, index) in dropLogItem"
                :key="index"
                @click="item.action ? item.action() : navigateTo(item.route)"
                :class="[
                  'flex items-center px-4 py-2 cursor-pointer',
                  item.label.includes('Priyanshi')
                    ? 'text-[#28B14C]  font-bold font-nunito'
                    : 'hover:text-[#05AEEF]',
                ]"
                @mouseover="handleHover(item, true)"
                @mouseleave="handleHover(item, false)"
              >
                <!-- Column 1: Profile Icon -->
                <div class="flex-shrink-0">
                  <img
                    :src="item.icon"
                    :alt="item.alt"
                    :class="
                      item.label.includes('Priyanshi')
                        ? 'w-10 h-10'
                        : 'md:w-7 md:h-5 w-7 h-5 '
                    "
                  />
                </div>

                <!-- Column 2: Name and Phone Number -->
                <div class="flex flex-col ml-4">
                  <span>{{ item.label.split("\n")[0] }}</span>
                  <span
                    v-if="item.label.includes('Priyanshi')"
                    class="text-sm text-[#28B14C] font-nunito font-normal"
                  >
                    {{ item.label.split("\n")[1] }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="relative lg:flex items-center hidden" @click="toggleHam">
          <a class="text-4xl cursor-pointer text-[#28B14C]">
            <img src="../../assets/List.png" class="h-6 w-6" />
          </a>
        </div>
      </div>

      <div
        v-if="showDropdown"
        class="absolute right-8 top-[calc(100%+1px)] w-48 lg:w-56 bg-white border rounded-lg navShadow z-50 text-[#28B14C] font-nunito font-normal md:leading-5 md:text-base"
      >
        <ul class="py-2">
          <li
            v-for="(item, index) in dropdownItemsMobile"
            :key="index"
            @click="item.action ? item.action() : navigateTo(item.route)"
            :class="[
              'flex items-center px-4 py-2 cursor-pointer',
              item.label.includes('Priyanshi')
                ? 'text-[#28B14C] font-bold font-nunito'
                : 'hover:text-[#05AEEF]',
            ]"
            @mouseover="handleHover(item, true)"
            @mouseleave="handleHover(item, false)"
          >
            <!-- Column 1: Profile Icon -->
            <div class="flex-shrink-0">
              <img
                :src="item.tempIcon || item.icon"
                :alt="item.alt"
                :class="
                  item.label.includes('Priyanshi')
                    ? 'w-10 h-10'
                    : 'md:w-7 md:h-5 w-5 h-5'
                "
              />
            </div>

            <!-- Column 2: Name and Phone Number -->
            <div class="flex flex-col ml-4">
              <span>{{ item.label.split("\n")[0] }}</span>
              <span
                v-if="item.label.includes('Priyanshi')"
                class="text-sm text-[#28B14C] font-nunito font-normal"
              >
                {{ item.label.split("\n")[1] }}
              </span>
            </div>
          </li>
        </ul>
      </div>

      <!-- Mobile Menu Dropdown -->
      <div
        v-if="showMenu"
        class="absolute top-0 h-screen left-0 w-3/4 bg-white navShadow z-50 text-[#28B14C]"
      >
        <ul class="py-2">
          <li
            v-for="(item, index) in dropdownItemsMobile"
            :key="index"
            @click="item.action ? item.action() : navigateTo(item.route)"
            :class="[
              'flex items-center px-4 py-2 cursor-pointer',
              item.label.includes('Priyanshi')
                ? 'text-[#28B14C]  font-bold font-nunito'
                : 'hover:text-[#05AEEF]',
            ]"
          >
            <!-- Column 1: Profile Icon -->
            <div class="flex-shrink-0">
              <img
                :src="item.icon"
                :alt="item.alt"
                :class="
                  item.label.includes('Priyanshi')
                    ? 'w-10 h-10'
                    : 'md:w-7 md:h-5 w-7 h-5 '
                "
              />
            </div>

            <!-- Column 2: Name and Phone Number -->
            <div class="flex flex-col ml-4">
              <span>{{ item.label.split("\n")[0] }}</span>
              <span
                v-if="item.label.includes('Priyanshi')"
                class="text-sm text-[#28B14C] font-nunito font-normal"
              >
                {{ item.label.split("\n")[1] }}
              </span>
            </div>
          </li>
        </ul>
      </div>

      <LogOne v-if="showLoginModal" @close="showLoginModal = false" />
    </nav>
  </div>
</template>

<script>
import LogOne from "../LoginScreen/LogOne.vue";
import SearchBox from "./SearchBox.vue";

export default {
  name: "MainNavbar",
  components: {
    LogOne,
    SearchBox,
  },
  data() {
    return {
      navbarFixed: true,
      showDropdown: false,
      showDropLogdown: false,
      showLoginModal: false,
      showMenu: false, // State for mobile menu
      dropLogItem: [
        {
          label: `Priyanshi
9476380238`,
          route: "UserProfile",
          icon: require("@/assets/UserCircle1.png"),
          alt: "Profile",
        },
        {
          label: "Logout",
          route: null,
          icon: require("@/assets/SignOut.png"),
          hoverIcon: require("@/assets/SignOutB.png"),
          alt: "Logout",
        },
      ],

      dropdownItemsMobile: [
        {
          label: `Priyanshi
        9476380238`,
          route: "UserProfile",
          icon: require("@/assets/UserCircle1.png"),
          alt: "Profile",
        },
        {
          label: "Order History",
          route: "OrderPage",
          hoverIcon: require("@/assets/cartNB.png"),
          icon: require("@/assets/cartN.png"),
          alt: "Orders",
        },
        // {
        //   label: "Your Profile",
        //   route: "UserProfile",
        //   icon: require("@/assets/UserCircle.png"),
        //   alt: "Profile",
        // },

        {
          label: "About Us",
          route: "AboutPage",
          icon: require("@/assets/UsersThree.png"),
          hoverIcon: require("@/assets/UsersThreeB.png"),
          alt: "about",
        },
        {
          label: "Products",
          route: "Products",
          icon: require("@/assets/product.png"),
          hoverIcon: require("@/assets/productB.png"),
          alt: "about",
        },
        {
          label: "Referral",
          route: "ReferralPage",
          icon: require("@/assets/refer.png"),
          hoverIcon: require("@/assets/referB.png"),
          alt: "about",
        },
        {
          label: "Reviews",
          route: null,
          icon: require("@/assets/review.png"),
          hoverIcon: require("@/assets/reviewB.png"),
          alt: "review",
          action: () => this.scrollToSection("testimonials"),
        },
        {
          label: "Woo cash",
          route: "WooCashPage",
          icon: require("@/assets/Vector.png"),
          hoverIcon: require("@/assets/VectorB.png"),
          alt: "testimonial",
        },
        {
          label: "Manage Address",
          route: "AddressPage",
          icon: require("@/assets/map.png"),
          hoverIcon: require("@/assets/mapB.png"),
          alt: "Address",
        },
        {
          label: "Contact Us",
          route: "ContactPage",
          icon: require("@/assets/call.png"),
          hoverIcon: require("@/assets/callB.png"),
          alt: "Help",
        },
      ],
      dropdownItems: [
        {
          label: `Priyanshi
        9476380238`,
          route: "UserProfile",
          icon: require("@/assets/UserCircle1.png"),
          alt: "Profile",
        },

        {
          label: "Order History",
          route: "OrderPage",
          icon: require("@/assets/cartN.png"),
          hoverIcon: require("@/assets/cartNB.png"),
          alt: "Orders",
        },
        {
          label: "Referral",
          route: "ReferralPage",
          icon: require("@/assets/refer.png"),
          hoverIcon: require("@/assets/referB.png"),

          alt: "about",
        },
        {
          label: "About Us",
          route: "AboutPage",
          icon: require("@/assets/UsersThree.png"),
          hoverIcon: require("@/assets/UsersThreeB.png"),
          alt: "about",
        },
        {
          label: "Products",
          route: "Products",
          icon: require("@/assets/product.png"),
          hoverIcon: require("@/assets/productB.png"),
          alt: "about",
        },

        {
          label: "Reviews",
          route: null,
          icon: require("@/assets/review.png"),
          hoverIcon: require("@/assets/reviewB.png"),
          alt: "review",
          action: () => this.scrollToSection("testimonials"),
        },
        {
          label: "Woo cash",
          route: "WooCashPage",
          icon: require("@/assets/Vector.png"),
          hoverIcon: require("@/assets/VectorB.png"),
          alt: "woo",
        },

        {
          label: "Manage Address",
          route: "AddressPage",
          icon: require("@/assets/map.png"),
          hoverIcon: require("@/assets/mapB.png"),
          alt: "Address",
        },
        {
          label: "Contact Us",
          route: "ContactPage",
          icon: require("@/assets/call.png"),
          hoverIcon: require("@/assets/callB.png"),
          alt: "Help",
        },
      ],
    };
  },
  methods: {
    toggleHam() {
      this.showDropdown = !this.showDropdown;
    },
    toggleLog() {
      this.showDropLogdown = !this.showDropLogdown;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        this.showDropdown = false;
        this.showMenu = false;
      }
    },
    handleScroll() {
      this.navbarFixed = window.scrollY > 1;
    },
    navigateTo(route) {
      if (route) {
        this.$router.push({ name: route });
      }
      this.showDropdown = false;
      this.showMenu = false;
      this.showDropLogdown = false;
    },
    openLoginModal() {
      this.showLoginModal = true;
    },
    openCart() {
      this.$emit("toggle-cart");
    },
    handleHover(item, isHovering) {
      if (item.hoverIcon) {
        // Use a temporary icon field to toggle the hover effect
        item.tempIcon = isHovering ? item.hoverIcon : null;
      }
    },
  },
};
</script>

<style>
.scrolled {
  position: fixed;
  top: 0;
}
.navShadow {
  box-shadow: 0px 10px 14px 0px rgba(74, 58, 255, 0.01),
    0px 9px 26px 0px rgba(23, 15, 73, 0.05);
}
</style>
