<template>
  <section
    class="about-us h-max lg:mt-0 px-4 md:px-0 md:mt-0 mt-10 lg:mb-8 mb-4 md:mb-0"
  >
    <div
      class="flex flex-col-reverse lg:flex-row items-stretch justify-between md:h-max lg:h-auto"
    >
      <div
        class="left-col w-full lg:w-1/2 md:pl-12 lg:py-0 md:py-0 md:pr-16 pr-0 flex flex-col justify-center overflow-hidden h-max"
      >
        <div class="grid grid-cols-3 gap-y-4 justify-items-center">
          <div
            v-for="(certification, index) in certifications.slice(0, 3)"
            :key="index"
            class="flex flex-col items-center"
          >
            <div
              class="flex items-center justify-center lg:h-20 h-auto mt-6 lg:mt-0"
            >
              <img
                :src="certification.image"
                :alt="'Icon ' + (index + 1)"
                class="md:w-16 md:h-auto w-8 h-8"
              />
            </div>
            <span
              class="lg:text-sm text-[8px] font-bold font-nunito leading-5 mt-2 text-center"
            >
              {{ certification.text }}
            </span>
          </div>
        </div>

        <!-- Centered Row for Last Two Items -->
        <div
          v-if="certifications.length > 3"
          class="flex justify-center gap-8 md:gap-32 mt-6"
        >
          <div
            v-for="(certification, index) in certifications.slice(3)"
            :key="index"
            class="flex flex-col items-center"
          >
            <div
              class="flex items-center justify-center lg:h-20 h-auto mt-6 lg:mt-0"
            >
              <img
                :src="certification.image"
                :alt="'Icon ' + (index + 4)"
                class="md:w-16 md:h-auto w-8 h-8"
              />
            </div>
            <span
              class="lg:text-sm text-[8px] font-bold font-nunito leading-5 mt-2 text-center"
            >
              {{ certification.text }}
            </span>
          </div>
        </div>

        <div
          class="mt-6 lg:mt-10 md:px-6 mb-8 md:mb-0 md:text-center lg:text-left"
        >
          <p
            class="md:text-base text-xs font-poppins font-bold leading-relaxed md:mb-4 text-[#4E4E4E]"
          >
            Safe for you. Thought around you.
          </p>
          <p
            class="leading-relaxed text-[#4E4E4E] md:py-0 py-4 font-poppins md:text-base text-xs font-normal capitalize"
          >
            Nua products have it all with 100% safe ingredients and designs
            suited to your life.
          </p>
        </div>
      </div>
      <div
        class="right-col w-full lg:w-1/2 flex items-center justify-center h-full md:mt-16 lg:mt-0"
      >
        <img
          :src="aboutImage"
          alt="About Us Image"
          class="md:w-auto md:h-auto w-80 h-56 object-cover"
        />
      </div>
    </div>
  </section>
</template>

<script>
import sc1 from "@/assets/sc1.png";
import sc2 from "@/assets/sc2.png";
import sc3 from "@/assets/sc3.png";
import sc4 from "@/assets/sc4.png";
// import sc5 from "@/assets/sc5.png";
import aboutImage from "@/assets/aboutThree.png";

export default {
  name: "AboutUsThree",
  data() {
    return {
      aboutImage,
      certifications: [
        { image: sc1, text: "GMP certified" },
        { image: sc2, text: "BIS 5405:2019" },
        { image: sc3, text: "ISO 13485-certified" },
        { image: sc4, text: "FDA Approved" },
        { image: sc1, text: "ISO 14001-2015-certified" },
      ],
    };
  },
};
</script>
