<template>
  <footer class="bg-[#28B14C] text-white md:mt-6 mt-8">
    <div class="lg:mx-24 md:mx-8 md:py-20 mx-4 py-7">
      <div class="grid grid-cols-1 lg:grid-cols-2 text-white">
        <!-- Logo and Contact Info -->
        <div class="flex flex-col mb-7 md:mb-0">
          <div class="mb-6">
            <img src="@/assets/WofreeFooter.png" alt="Logo" />
          </div>
          <div class="space-y-4 md:w-4/5 w-[80%]">
            <div class="flex">
              <span class="mr-2">
                <img
                  src="@/assets/loc.png"
                  alt="Location Icon"
                  class="w-4 h-4"
                />
              </span>
              <p class="font-nunito font-normal text-sm">
                123, Patna, Bihar 800001, India
              </p>
            </div>
            <div class="flex">
              <span class="mr-2">
                <img
                  src="@/assets/email.png"
                  alt="Email Icon"
                  class="w-4 h-4"
                />
              </span>
              <p class="font-nunito font-normal text-sm">
                Wofreesupport@gmail.com
              </p>
            </div>
            <div class="flex">
              <span class="mr-2">
                <img
                  src="@/assets/phone.png"
                  alt="Phone Icon"
                  class="w-4 h-4"
                />
              </span>
              <p class="font-nunito font-normal text-sm">
                +91 8476908754 / +91 8476908754
              </p>
            </div>
          </div>
        </div>

        <!-- Accordion Sections -->
        <div
          class="grid grid-cols-1 md:grid-cols-4 md:gap-4 items-baseline md:mt-10 lg:mt-0"
        >
          <div
            class="text-white"
            v-for="section in accordionSections"
            :key="section.name"
          >
            <h3
              @click="toggleAccordion(section.name)"
              class="cursor-pointer font-semibold md:text-sm text-sm mb-4 font-inter flex justify-between items-center"
            >
              {{ section.label }}
              <span class="ml-2 md:hidden">
                {{ accordion[section.name] ? "-" : "+" }}
              </span>
            </h3>
            <ul
              v-show="accordion[section.name] || isDesktop"
              class="font-normal space-y-3 md:text-sm text-xs font-dm"
            >
              <li v-for="link in section.links" :key="link.text">
                <router-link
                  v-if="link.to"
                  :to="link.to"
                  class="hover:underline md:text-xs text-xs"
                  >{{ link.text }}</router-link
                >
                <a
                  v-else
                  @click="scrollToSection(link.id)"
                  class="hover:underline"
                  >{{ link.text }}</a
                >
              </li>
            </ul>
            <!-- Conditionally render the hr after the list -->
            <hr class="border-white my-4 md:hidden" />
          </div>
        </div>
      </div>

      <hr
        class="md:my-8 mt-5 mb-[11px] hidden md:flex self-center border-white border-1"
      />
      <div class="flex flex-row md:flex-row justify-between text-sm">
        <div class="text-white">
          <p class="md:text-base font-normal text-xs font-nunito md:mt-1">
            © 2024 Wofree
          </p>
        </div>
        <div className="flex">
          <a
            href="https://indiclancers.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex 
      text-black text-[10px] button-shadow bg-white text-base font-bold p-[3px] md:p-[6px] rounded-[2px]"
          >
            <img
              src="@/assets/indic.png"
              alt="Logo"
              className="w-6 h-6 md:mr-[6px]"
            />
            Made By Indic Lancers
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      accordion: {
        quickLinks: false,
        mainPages: false,
        ourProducts: false,
        company: true,
      },
      accordionSections: [
        {
          name: "company",
          label: "Legal Pages",
          links: [
            { text: "Terms of Service", to: "/term-of-service" },
            { text: "Return and Exchange Policy", to: "/return-cancel-policy" },
            { text: "Privacy Policy", to: "/privacy-policy" },
            { text: "Cookie Policy", to: "/cookies-policy" },
          ],
        },
        {
          name: "mainPages",
          label: "Main Pages",
          links: [
            { text: "About Us", to: "/about-us" },
            // { text: "Cart", to: "#" },
            { text: "Products", to: "/products" },
            { text: "Blog", id: "blog" },
          ],
        },
        {
          name: "ourProducts",
          label: "Our Products",
          links: [
            { text: "Product 1", to: "/products" },
            { text: "Product 2", to: "/products" },
            { text: "Product 3", to: "/products" },
          ],
        },
        {
          name: "quickLinks",
          label: "Quick Links",
          links: [
            { text: "Track orders", to: "/your-orders" },
            { text: "Woo Cash", to: "/your-orders" },
            // { text: "Contact Us", to: "/contact-us" },
          ],
        },
      ],
    };
  },
  computed: {
    isDesktop() {
      return window.innerWidth >= 577; // Adjust the breakpoint as necessary
    },
  },
  methods: {
    toggleAccordion(section) {
      if (!this.isDesktop) {
        this.accordion[section] = !this.accordion[section];
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
.button-shadow {
  filter: drop-shadow(0px 2.614px 2.614px rgba(0, 0, 0, 0.25));
}
</style>
