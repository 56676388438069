<template>
  <div
    @click="goToDetail"
    :class="[
      'blog-card',
      {
        'shadow-none': shadowClass === 'none',
        'border-none': borderClass === 'none',
      },
      backgroundClass,
      'p-2',
      'lg:p-4',
    ]"
  >
    <div class="flex flex-col">
      <div class="flex items-center md:mt-4 mt-2 h-44">
        <img
          v-if="showImage"
          :src="blog.image"
          alt="Blog Image"
          :style="{ width: imageWidth, height: imageHeight }"
          class="object-cover md:mb-4 mb-2 w-36 h-48"
        />
      </div>

      <!-- Category and Reading Time Below the Image -->
      <div v-if="showCategory" class="md:py-1 flex justify-between">
        <p
          class="text-[#40B2C9] md:text-sm text-[10px] md:font-medium md:leading-3"
        >
          {{ blog.category }}
        </p>
        <p
          class="md:text-xs text-[10px] text-[#121212] md:leading-3 opacity-30"
        >
          {{ blog.readingTime }}
        </p>
      </div>

      <h3
        v-if="!titleBelowImage"
        class="md:text-lg text-[10px] font-bold md:mt-3 font-nunito md:leading-7 md:mb-2 ml-4"
      >
        {{ blog.title }}
      </h3>

      <h3
        v-if="titleBelowImage"
        class="md:text-base text-[10px] font-bold md:mt-3 font-nunito leading-5 mt-1 md:leading-7 md:mb-2"
      >
        {{ blog.title }}
      </h3>

      <p
        v-if="showDescription"
        class="font-poppins md:text-sm md:leading-6 hidden md:flex font-normal text-[#121212] opacity-70"
      >
        {{ blog.shortDesc }}
      </p>

      <hr class="hidden md:inline border my-4" />

      <div class="md:mt-1 flex mt-2 justify-between" v-if="showAuthor">
        <div class="flex gap-4">
          <img :src="blog.author" class="md:h-8 md:w-8 w-6 h-6" />
          <p class="md:text-sm text-[8px] font-normal leading-2">
            {{ blog.authorName }}
          </p>
        </div>

        <p class="md:text-xs text-[8px] text-[#2C3A4B] opacity-70">
          {{ daysAgo(blog.datePosted) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    blog: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    showCategory: {
      type: Boolean,
      default: true,
    },
    showAuthor: {
      type: Boolean,
      default: true,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    titleBelowImage: {
      type: Boolean,
      default: false, // false by default, showing title next to image
    },
    imageWidth: {
      type: String,
      default: "100%",
    },
    imageHeight: {
      type: String,
      default: "160px",
    },
    padding: {
      type: String,
      default: "16px",
    },
    shadowClass: {
      type: String,
      default: "shadow-md",
    },
    borderClass: {
      type: String,
      default: "border border-gray-300",
    },
    backgroundClass: {
      type: String,
      default: "bg-white",
    },
  },
  methods: {
    goToDetail() {
      this.$emit("go-to-detail", this.index);
    },
    daysAgo(date) {
      const postDate = new Date(date);
      const currentDate = new Date();
      const diffInTime = currentDate - postDate;
      const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
      return diffInDays === 0 ? "Today" : `${diffInDays} days ago`;
    },
  },
};
</script>

<style scoped>
.blog-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  /* padding: 16px; */
  transition: box-shadow 0.2s;
}

.shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: none !important;
}
</style>
