<template>
  <section class="flex flex-col md:mt-14 lg:mx-24 md:mx-8 mx-4">
    <!-- Top Header Section -->
    <div class="text-left mb-4 mt-10 md:mt-0">
      <h1 class="lg:text-4xl md:text-4xl font-semibold text-[#1E1E1E]">
        Guides, Woo Flow
      </h1>
    </div>

    <!-- Subheading Section -->
    <p
      class="text-left lg:text-xl text-sm lg:leading-8 font-normal md:text-lg md:mb-10 text-[#0F1935]"
    >
      Stories about the female anatomy, the menstrual cycle, and women's
      wellness from around the world.
    </p>

    <!-- Auto Slider for mobile view -->
    <div
      class="lg:hidden relative overflow-hidden md:grid-cols-3 h-fit mt-10 md:mt-0"
    >
      <div
        class="flex transition-all duration-500"
        :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
      >
        <div
          v-for="(blog, index) in blogs"
          :key="index"
          class="w-full flex-shrink-0"
        >
          <BlogCard
            :blog="blog"
            :index="index"
            :title-below-image="true"
            @go-to-detail="goToDetail"
          />
        </div>
      </div>

      <!-- Slide indicators -->
      <div class="flex justify-center mt-4">
        <span
          v-for="(blog, index) in blogs"
          :key="index"
          :class="{
            'bg-[#05AEEF] border border-gray-300': currentSlide === index,
            'bg-gray-400  border border-gray-300': currentSlide !== index,
          }"
          class="w-2.5 h-2.5 rounded-full mx-1 cursor-pointer"
          @click="setSlide(index)"
        ></span>
      </div>
    </div>

    <!-- Blog Grid with Pagination for desktop view -->
    <div class="hidden lg:grid grid-cols-3 gap-24 cursor-pointer">
      <BlogCard
        v-for="(blog, index) in paginatedBlogs"
        :key="index"
        :blog="blog"
        :index="index"
        @go-to-detail="goToDetail"
        backgroundClass="custom-bg"
        :title-below-image="true"
        :showAuthor="true"
      />
    </div>

    <!-- Pagination controls -->
    <div class="hidden lg:flex justify-center mt-10 space-x-2">
      <button
        @click="previousPage"
        :disabled="currentPage === 1"
        class="px-3 py-2 rounded-[32px] bg-white lg:h-8 lg:w-8 items-center text-blue-500 hover:bg-gray-300"
      >
        &lt;
      </button>

      <!-- Page numbers -->
      <button
        v-for="page in totalPages"
        :key="page"
        @click="goToPage(page)"
        :class="{
          'bg-green-500 text-white border  border-gray-300':
            currentPage === page,
          'bg-white hover:bg-white border  border-gray-300  text-[#05AEEF] ':
            currentPage !== page,
        }"
        class="px-2 py-2 lg:w-10 lg:h-10 rounded-2xl"
      >
        {{ page }}
      </button>

      <button
        @click="nextPage"
        :disabled="currentPage === totalPages"
        class="px-3 py-2 rounded-[32px] lg:w-12 lg:h-10 items-center text-blue-500 hover:bg-gray-300"
      >
        <p class="">&gt;</p>
      </button>
    </div>
  </section>
</template>

<script>
import { blogs } from "../blogData";
import BlogCard from "../BlogCard.vue";

export default {
  components: {
    BlogCard,
  },
  data() {
    return {
      blogs,
      currentSlide: 0,
      slideInterval: null,
      currentPage: 1,
      blogsPerPage: 3,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.blogs.length / this.blogsPerPage);
    },
    paginatedBlogs() {
      const start = (this.currentPage - 1) * this.blogsPerPage;
      return this.blogs.slice(start, start + this.blogsPerPage);
    },
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
  methods: {
    startAutoSlide() {
      this.slideInterval = setInterval(() => {
        this.nextSlide();
      }, 3000);
    },
    stopAutoSlide() {
      clearInterval(this.slideInterval);
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.blogs.length;
    },
    setSlide(index) {
      this.currentSlide = index;
    },
    goToAllBlogs() {
      this.$router.push({ name: "AllBlogs" });
    },
    goToDetail(index) {
      this.$router.push({ name: "blog-detail", params: { id: index } });
    },
    goToPage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>

<style scoped>
.custom-bg {
  background: rgba(40, 177, 76, 0.06);
}
</style>
