<template>
  <div class="lg:p-0 lg:my-16 my-10 md:mx-20 mx-4 md:mt-0" id="testimonials">
    <h2
      class="lg:text-5xl text-xl font-bold text-center md:mb-16 mb-10 font-lato"
    >
      What Our Customers Say About Us
    </h2>

    <div class="relative mt-4">
      <div
        class="relative md:h-auto lg:h-max h-auto overflow-hidden transition-transform duration-500 ease-in-out"
      >
        <transition>
          <div
            key="testimonial-slide"
            class="flex overflow-hidden transition-transform duration-500"
            :style="{
              transform: `translateX(-${
                currentSlide * (100 / itemsPerSlide)
              }%)`,
            }"
          >
            <div
              :class="`grid md:grid-cols-${itemsPerSlide} grid-col-1 md:gap-10`"
            >
              <TestimonialCard
                v-for="(testimonial, index) in currentTestimonials"
                :key="index"
                :testimonial="testimonial"
                class=""
              />
            </div>
          </div>
        </transition>
      </div>
      <div
        class="absolute inset-x-0 md:bottom bottom-[-1.75rem] flex justify-center mt-10 insert-y-2 space-x-2"
      >
        <span
          v-for="(testimonial, index) in totalSlides"
          :key="index"
          :class="{
            'md:w-3 md:h-3 w-2 h-2 rounded-full cursor-pointer': true,
            'bg-black': currentIndex === index,
            'bg-[#28B14C]': currentIndex !== index,
          }"
          @click="goToSlide(index)"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { testimonials } from "./customerData";
import TestimonialCard from "./TestimonialCard.vue";

export default {
  components: {
    TestimonialCard,
  },
  data() {
    return {
      currentIndex: 0,
      testimonials,
      autoSlideInterval: null,
      itemsPerSlide: window.innerWidth < 1020 ? 1 : 3,
    };
  },
  computed: {
    currentTestimonials() {
      const start = this.currentIndex * this.itemsPerSlide;
      return this.testimonials.slice(start, start + this.itemsPerSlide);
    },
    totalSlides() {
      return Math.ceil(this.testimonials.length / this.itemsPerSlide);
    },
  },
  methods: {
    goToSlide(index) {
      this.currentIndex = index;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.totalSlides;
    },
    startAutoSlide() {
      this.autoSlideInterval = setInterval(this.nextSlide, 3000);
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    },
    updateItemsPerSlide() {
      this.itemsPerSlide = window.innerWidth < 768 ? 1 : 3;
    },
  },
  mounted() {
    this.startAutoSlide();
    window.addEventListener("resize", this.updateItemsPerSlide);
  },
  beforeUnmount() {
    this.stopAutoSlide();
    window.removeEventListener("resize", this.updateItemsPerSlide);
  },
};
</script>

<style scoped>
.current-slide {
  opacity: 1;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
