<template>
  <div class="w-full bg-[#28B14C] rounded shadow-lg py-4 px-4">
    <div class="flex justify-between gap-4 self-center items-center">
      <!-- Left Column -->
      <div>
        <h2 class="text-white md:text-lg text-sm font-bold font-nunito mb-2">
          Refer Now
        </h2>
        <p
          class="text-white md:text-sm text-xs leading-4 font-nunito font-normal"
        >
          {{
            isLoggedIn
              ? "Share your unique code with you friends and family to unlock best offers"
              : "Log in, and share your invite code. That's all you have to do."
          }}
        </p>
      </div>
      <div>
        <button
          v-if="isLoggedIn"
          @click="copyCode"
          class="bg-white font-nunito md:text-base text-xs text-green-700 font-bold md:py-2 py-1 md:px-4 px-2 rounded hover:bg-gray-100"
        >
          <div class="flex">
            <img
              src="@/assets/copyIcon.png"
              class="md:h-5 md:w-5 w-3 h-3 md:mr-2"
              alt="Copy Icon"
            />
            <span class="lg:text-sm text-[10px] px-2">{{ inviteCode }}</span>
          </div>
        </button>
        <button
          v-else
          class="bg-white font-nunito w-max md:text-base text-xs text-green-700 font-bold md:py-2 py-2 px-4 md:px-4 rounded-lg shadow-md"
        >
          Get Code
        </button>
      </div>
    </div>
  </div>

  <!-- New Referral Code Input Section (only visible when logged in) -->
  <div
    v-if="isLoggedIn"
    class="md:mt-6 my-5 md:my-0 flex md:p-6 p-4 md:gap-8 shadow-sm items-center gap-4 bg-[#F7F8F9] rounded"
  >
    <div class="text-[#339933]">
      <p class="md:text-lg font-bold text-xs font-nunito mb-2">
        Enter referal code
      </p>
      <p class="lg:text-sm font-normal text-xs font-nunito">
        Enter unique code to earn cashback in your wallet
      </p>
    </div>
    <div class="relative md:w-1/2">
      <span
        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M14 3H2C1.86739 3 1.74021 3.05268 1.64645 3.14645C1.55268 3.24021 1.5 3.36739 1.5 3.5V12C1.5 12.2652 1.60536 12.5196 1.79289 12.7071C1.98043 12.8946 2.23478 13 2.5 13H13.5C13.7652 13 14.0196 12.8946 14.2071 12.7071C14.3946 12.5196 14.5 12.2652 14.5 12V3.5C14.5 3.36739 14.4473 3.24021 14.3536 3.14645C14.2598 3.05268 14.1326 3 14 3ZM12.7144 4L8 8.32187L3.28562 4H12.7144ZM13.5 12H2.5V4.63688L7.66187 9.36875C7.75412 9.45343 7.87478 9.50041 8 9.50041C8.12522 9.50041 8.24588 9.45343 8.33813 9.36875L13.5 4.63688V12Z"
            fill="#C4C4C4"
          />
        </svg>
      </span>
      <input
        type="text"
        class="w-full md:w-4/5 lg:w-4/5 pl-10 pr-4 py-2 border border-dashed border-black text-green-700 text-xs font-nunito rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 placeholder-gray-500"
        placeholder="Enter code"
      />
    </div>
  </div>
  <div class="flex justify-center">
    <button
      @click="openRedeemPopup"
      class="bg-[#28B14C] shadow-sm text-sm md:mt-9 w-1/3 rounded text-white font-nunito font-semibold leading-4 py-3"
    >
      Redeem Now
    </button>
  </div>
  <!-- 
  <div class="mt-7 md:hidden">
    <p class="mb-6 text-[#05AEEF] font-normal leading-5 font-nunito text-sm">
      Share Via:
    </p>
    <button
      class="bg-[#28B14C] rounded-md w-full text-white py-3 text-sm font-medium leading-normal"
    >
      WhatsApp
    </button>
  </div> -->

  <RedeemPopup v-if="showRedeemPopup" @close="showRedeemPopup = false" />
</template>

<script>
import RedeemPopup from "./RedeemPop.vue";

export default {
  name: "ReferNow",
  components: { RedeemPopup },
  data() {
    return {
      isLoggedIn: true,
      inviteCode: "lorem123",
      showRedeemPopup: false,
    };
  },
  methods: {
    copyCode() {
      navigator.clipboard
        .writeText(this.inviteCode)
        .then(() => {
          alert("Invite code copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
    openRedeemPopup() {
      this.showRedeemPopup = true;
    },
  },
};
</script>
