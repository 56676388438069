<template>
  <div class="max-w-xl mx-auto p-8 rounded-lg bg md:mt-9">
    <div class="flex">
      <span class="md:mr-2"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_748_624)">
            <path
              d="M3.96938 12.9694C3.82899 12.8288 3.75009 12.6383 3.75 12.4397V3.75H12.4397C12.6383 3.75009 12.8288 3.82899 12.9694 3.96938L22.2806 13.2806C22.4212 13.4213 22.5001 13.612 22.5001 13.8108C22.5001 14.0096 22.4212 14.2003 22.2806 14.3409L14.3438 22.2806C14.2031 22.4212 14.0124 22.5001 13.8136 22.5001C13.6148 22.5001 13.4241 22.4212 13.2834 22.2806L3.96938 12.9694Z"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.875 8.8125C8.39277 8.8125 8.8125 8.39277 8.8125 7.875C8.8125 7.35723 8.39277 6.9375 7.875 6.9375C7.35723 6.9375 6.9375 7.35723 6.9375 7.875C6.9375 8.39277 7.35723 8.8125 7.875 8.8125Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_748_624">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs></svg
      ></span>
      <h1 class="font-semibold font-poppins text-xl md:leading-4 md:mb-5">
        Price Details
      </h1>
    </div>

    <h2
      class="md:mb-5 font-semibold md:ml-8 font-poppins text-base md:leading-4"
    >
      Bill Summary
    </h2>

    <div
      class="grid grid-cols-2 md:ml-8 gap-y-2 font-poppins md:text-base font-normal leading-4"
    >
      <div class="text-base font-normal font-poppins">Item Total</div>
      <div class="text-right font-medium">
        <span class="font-normal text-base mr-6 line-through">₹999</span>
        ₹{{ totalPrice ? totalPrice.toFixed(2) : "0.00" }}
      </div>

      <div class="text-base font-normal font-poppins">GST</div>
      <div class="text-right font-medium">₹{{ gst.toFixed(2) }}</div>

      <div class="text-base font-normal font-poppins">Delivery Fee</div>
      <div class="text-right font-medium">
        <span class="font-normal text-base mr-6">₹69</span> ₹{{
          deliveryFee.toFixed(2)
        }}
      </div>
    </div>

    <hr class="my-4 bg-black text-black border border-black h-[0.5px]" />

    <div class="grid grid-cols-2 font-normal md:ml-8 leading-4">
      <div class="font-poppins text-black">To Pay</div>
      <div class="text-right text-black font-semibold">
        <span class="font-normal text-base mr-6 line-through">₹999</span> ₹{{
          totalToPay ? totalToPay.toFixed(2) : "0.00"
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceDetail",
  props: {
    totalPrice: {
      type: Number,
      required: true,
    },
  },
  computed: {
    gst() {
      return this.totalPrice ? this.totalPrice * 0.1 : 0;
    },
    deliveryFee() {
      return 5;
    },
    totalToPay() {
      return this.totalPrice + this.gst + this.deliveryFee;
    },
  },
};
</script>
<style>
.bg {
  background: rgba(40, 177, 76, 0.14);
}
</style>
