<template>
  <div class="lg:mx-24 mx-4 md:my-10 mt-5 mb-10 md:mb-0">
    <div class="flex flex-col lg:flex-row items-center gap-0">
      <div class="flex items-center justify-center w-full">
        <div class="flex-grow h-[1px] bg-[#28B14C] md:hidden"></div>

        <div
          class="flex justify-center md:w-auto md:justify-center md:border border-[#28B14C] rounded md:p-2 md:mr-5"
        >
          <button
            class="bg-[#28B14C] text-white md:py-2 w-48 md:px-4 py-2 px-4 rounded md:text-xl text-base"
          >
            Certified Safe
          </button>
        </div>

        <!-- Right Line -->
        <div class="flex-grow h-[1px] bg-[#28B14C] md:hidden"></div>
      </div>

      <div class="flex flex-row mt-6 md:mt-10 lg:mt-0">
        <div class="flex items-center lg:w-72 md:w-auto w-30">
          <img
            src="@/assets/feature-one.png"
            alt="Icon"
            class="md:w-8 md:h-8 w-7 h-7 md:mr-6 mr-2"
          />
          <p class="text-[#000] md:text-xl text-[10px] font-normal">
            <span class="hidden lg:inline"> Our products are made with</span>
            <span class="font-bold"> safe ingredients</span>
          </p>
        </div>

        <div
          class="md:block md:h-15 h-16 border-l mx-3 lg:mx-1 md:mx-4 border-[#28B14C]"
        ></div>

        <div class="flex items-center lg:w-64 md:w-auto w-30">
          <img
            src="@/assets/feature-two.png"
            alt="Icon"
            class="md:w-8 md:h-8 w-7 h-7 md:mr-6 mr-2 md:mx-3"
          />
          <p class="text-[#000] md:text-lg text-[10px] font-normal">
            <span class="hidden lg:inline">
              Our products are <br class="hidden md:inline" />
              certified
            </span>
            <span class="font-bold">non-toxins</span>
          </p>
        </div>

        <div
          class="md:block h-15 border-l mx-4 lg:mx-4 md:mx-4 border-[#28B14C]"
        ></div>

        <div class="flex items-center lg:w-72 md:w-auto w-30">
          <img
            src="@/assets/feature-three.png"
            alt="Icon"
            class="md:w-8 md:h-8 w-7 h-7 md:mr-6 mr-2 md:mx-3"
          />
          <p class="text-[#000] md:text-lg text-[10px] font-normal">
            <span class="hidden lg:inline">
              Our products are clinically tested and</span
            >
            <span class="font-bold"> safe on skin </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainFeature",
};
</script>
