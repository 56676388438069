<template>
  <div
    class="flex flex-row items-center md:w-2/4 w-11/12 border border-black rounded-lg p-2 mx-4"
  >
    <span class="w-4 h-4 mr-2 md:mr-5">
      <!-- Search Icon -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M14.3538 13.6462L11.2244 10.5174C12.1314 9.42847 12.5837 8.03177 12.4871 6.61786C12.3906 5.20395 11.7527 3.88168 10.7061 2.92613C9.65948 1.97058 8.28478 1.45531 6.86794 1.48751C5.4511 1.51971 4.10122 2.0969 3.09911 3.09901C2.097 4.10113 1.51981 5.451 1.48761 6.86784C1.45541 8.28468 1.97068 9.65938 2.92623 10.706C3.88178 11.7526 5.20405 12.3905 6.61796 12.487C8.03187 12.5836 9.42856 12.1313 10.5175 11.2243L13.6462 14.3537C13.6927 14.4001 13.7479 14.437 13.8086 14.4621C13.8692 14.4872 13.9343 14.5002 14 14.5002C14.0657 14.5002 14.1308 14.4872 14.1914 14.4621C14.2521 14.437 14.3073 14.4001 14.3538 14.3537C14.4002 14.3072 14.4371 14.252 14.4622 14.1913C14.4873 14.1307 14.5003 14.0656 14.5003 13.9999C14.5003 13.9342 14.4873 13.8691 14.4622 13.8085C14.4371 13.7478 14.4002 13.6926 14.3538 13.6462ZM2.5 6.9999C2.5 6.10988 2.76392 5.23985 3.25838 4.49983C3.75285 3.75981 4.45566 3.18304 5.27792 2.84244C6.10019 2.50185 7.00499 2.41273 7.8779 2.58636C8.75082 2.76 9.55264 3.18858 10.182 3.81792C10.8113 4.44725 11.2399 5.24908 11.4135 6.12199C11.5872 6.99491 11.4981 7.89971 11.1575 8.72197C10.8169 9.54424 10.2401 10.247 9.50006 10.7415C8.76004 11.236 7.89001 11.4999 7 11.4999C5.80693 11.4986 4.66311 11.024 3.81948 10.1804C2.97585 9.33679 2.50132 8.19297 2.5 6.9999Z"
          fill="black"
        />
      </svg>
    </span>
    <input
      type="text"
      placeholder="Search"
      class="w-full px-2 md:px-0 border-none text-black focus:outline-none placeholder-black font-poppins text-sm"
    />
  </div>
</template>

<script>
export default {
  name: "MobileSearchInput",
};
</script>

<style scoped>
/* Tailwind is used for styling */
</style>
