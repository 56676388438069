<template>
  <div
    :class="[
      'border rounded-lg md:px-4 px-[6px] md:w-[150px] py-2 w-[120px] lg:w-[150px] cursor-pointer items-center transition-all h-auto',
      isSelected ? 'border-green-500 bg-green-50' : 'border-gray-300',
    ]"
    @click="onSelect"
  >
    <!-- Pack Title -->
    <h3
      class="md:text-sm text-[11px] font-bold flex items-center whitespace-nowrap"
    >
      <!-- Conditionally render dot or radio button based on `useRadioButton` prop -->
      <span
        v-if="!useRadioButton && showDot"
        class="md:mr-4 mr-1 w-2 text-xl"
        :style="{ color: color }"
        >&#8226;</span
      >
      <input
        v-else-if="useRadioButton"
        type="radio"
        :checked="isSelected"
        class="md:mr-4 mr-2"
      />
      <span
        class="md:text-sm text-[11px] font-semibold font-nunito text-black"
        >{{ title }}</span
      >
    </h3>

    <!-- Tick and Description -->
    <div class="flex items-center md:mt-2">
      <component :is="tickIcon" v-if="tickIcon" width="15" height="15" />
      <span
        class="md:text-[11px] font-normal text-[#28B14C] text-[10px] font-nunito leading-5 md:ml-[30px] ml-5"
      >
        {{ tickText }}
      </span>
    </div>

    <div class="flex items-center">
      <component :is="crossIcon" v-if="crossIcon" width="15" height="15" />
      <span
        class="md:text-[11px] font-normal line-through text-[#6F6F6F] text-[10px] font-nunito leading-5 md:ml-[30px] ml-5"
      >
        {{ crossText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    tickText: String,
    crossText: String,
    isSelected: Boolean,
    color: {
      type: String,
      default: "#28B14C",
    },
    showDot: {
      type: Boolean,
      default: true,
    },
    useRadioButton: {
      type: Boolean,
      default: false,
    },
    widthClass: {
      type: String,
      default: "auto",
    },
  },
  methods: {
    onSelect() {
      this.$emit("select");
    },
  },
};
</script>
