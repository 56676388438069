<template>
  <div
    class="flex lg:items-start lg:flex-col flex-col md:my-10 my-10 mt-10 mx-0 lg:mx-24 md:mt-20"
  >
    <span
      class="md:text-2xl text-xl md:text-left text-center font-bold font-nunito mb-5 md:mb-10"
    >
      As seen in-
    </span>

    <!-- Auto-scrolling carousel for small screens -->
    <div v-if="isMobile" class="carousel-wrapper overflow-hidden">
      <div class="carousel">
        <img
          v-for="(src, index) in images"
          :key="index"
          :src="src"
          :alt="'Icon ' + (index + 1)"
          class="carousel-item"
        />
        <!-- Duplicate images for seamless scrolling -->
        <img
          v-for="(src, index) in images"
          :key="'dup-' + index"
          :src="src"
          :alt="'Icon ' + (index + 1)"
          class="carousel-item"
        />
      </div>
    </div>

    <!-- Static grid for larger screens -->
    <div
      v-else
      class="grid grid-cols-3 md:grid-cols-5 md:gap-[104px] items-center"
    >
      <img
        v-for="(src, index) in images"
        :key="index"
        :src="src"
        :alt="'Icon ' + (index + 1)"
        class="w-36"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SeenIn",
  data() {
    return {
      images: [
        require("@/assets/s1.png"),
        require("@/assets/s2.png"),
        require("@/assets/s3.png"),
        require("@/assets/s4.png"),
        require("@/assets/s5.png"),
      ],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
</script>

<style scoped>
/* Carousel container */
.carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.carousel {
  display: flex;
  animation: scroll 10s linear infinite;
}

/* Style for each image */
.carousel-item {
  width: 120px;
  height: 48px;
  margin-right: 16px;
  object-fit: contain;
}

/* Marquee effect */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
