<template>
  <div class="flex items-center justify-between p-4 border-b">
    <img
      :src="item.img"
      alt="Product Image"
      class="md:w-24 md:h-28 w-24 h-24 mr-4 object-cover border-2 rounded-lg border-[#28B14C] p-3"
    />
    <div class="ml-4 flex-1">
      <div class="flex justify-between">
        <h4 class="md:text-base font-poppins text-[#16162E] font-normal">
          {{ item.name }}
        </h4>

        <button @click="removeItem">
          <img src="@/assets/Delete.png" class="md:w-6 md:h-6 w-6 h-6" />
        </button>
      </div>

      <p class="text-[#8B8B97] text-xs font-normal">1 pack (10 Panties)</p>
      <div class="flex justify-between">
        <div class="flex items-center mt-2">
          <button
            @click="decreaseQuantity"
            class="border px-2 border-[#D9D9D9] rounded-lg"
          >
            <span class="text-[#40AA54]">-</span>
          </button>
          <span class="mx-2">{{ item.quantity }}</span>
          <button
            @click="increaseQuantity"
            class="border px-2 border-[#D9D9D9] rounded-lg"
          >
            <span class="text-[#40AA54]">+ </span>
          </button>
        </div>
        <p class="text-[#16162E] font-normal text-base font-nunito">
          ₹ {{ item.price }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    increaseQuantity() {
      this.$emit("increase-quantity", this.item);
    },
    decreaseQuantity() {
      this.$emit("decrease-quantity", this.item);
    },
    removeItem() {
      this.$emit("remove-item", this.item);
    },
  },
};
</script>
