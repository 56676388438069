// productData.js
export const products = [
  {
    id: 2,
    name: "SoftShield Pads",
    price: "299",
    description: "Maximum protection with a soft touch and comfort.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our pads are designed for maximum protection with a soft touch, ensuring comfort during use.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video2.mp4"), // Video file added here
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
  {
    id: 3,
    name: "Eco-Friendly Pads",
    price: "199",
    description: "Maximum protection with a soft touch and comfort.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our eco-friendly pads are made from sustainable materials that ensure comfort and protect the environment.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video3.mp4"), // Video file added here
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
  {
    id: 4,
    name: "Night Guard Pads",
    price: "₹349",
    description: "All-night protection for uninterrupted sleep.",
    feature: "4x fluid retention capacity",
    additionalInfo: ["Available in 2 sizes", "With Disposable Covers"],
    howItWorks: {
      description:
        "Our night guard pads provide all-night protection with superior comfort for uninterrupted sleep.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video4.mp4"), // Video file added here
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
  {
    id: 5,
    name: "Ultra Comfort Pads",
    price: "249",
    description: "Experience unparalleled comfort and protection.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our pads are ultra-thin and lined with a soft top sheet to maximize comfort. They are made of oxy-biodegradable material and 100% certified organic cotton. Pads have a 5x fluid retention capacity, which means they can be worn for long days and longer nights.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video1.mp4"), // Repeated video file
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
  {
    id: 6,
    name: "SoftShield Pads",
    price: "299",
    description: "Maximum protection with a soft touch and comfort.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our pads are designed for maximum protection with a soft touch, ensuring comfort during use.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video2.mp4"), // Repeated video file
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
  {
    id: 7,
    name: "SoftShield Pads",
    price: "299",
    description: "Maximum protection with a soft touch and comfort.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our pads are designed for maximum protection with a soft touch, ensuring comfort during use.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video3.mp4"), // Repeated video file
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
  {
    id: 8,
    name: "Night Guard Pads",
    price: "349",
    description: "All-night protection for uninterrupted sleep.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our night guard pads provide all-night protection with superior comfort for uninterrupted sleep.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video4.mp4"), // Repeated video file
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
  {
    id: 1,
    name: "Ultra Comfort Pads",
    price: "249",
    description: "Experience unparalleled comfort and protection.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our pads are ultra-thin and lined with a soft top sheet to maximize comfort. They are made of oxy-biodegradable material and 100% certified organic cotton. Pads have a 5x fluid retention capacity, which means they can be worn for long days and longer nights.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video1.mp4"), // Video file added here
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },

  {
    id: 1,
    name: "Ultra Comfort Pads",
    price: "249",
    description: "Experience unparalleled comfort and protection.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our pads are ultra-thin and lined with a soft top sheet to maximize comfort. They are made of oxy-biodegradable material and 100% certified organic cotton. Pads have a 5x fluid retention capacity, which means they can be worn for long days and longer nights.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video1.mp4"), // Video file added here
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
  {
    id: 1,
    name: "Ultra Comfort Pads",
    price: "249",
    description: "Experience unparalleled comfort and protection.",
    feature: "4x fluid retention capacity",
    additionalInfo: [
      "Available in 2 sizes",
      "With Disposable Covers",
      "50 % wider back, Leak Proof",
    ],
    howItWorks: {
      description:
        "Our pads are ultra-thin and lined with a soft top sheet to maximize comfort. They are made of oxy-biodegradable material and 100% certified organic cotton. Pads have a 5x fluid retention capacity, which means they can be worn for long days and longer nights.",
    },
    img: require("@/assets/productImg.png"),
    video: require("@/assets/video1.mp4"), // Video file added here
    images: [
      require("@/assets/productImg.png"),
      require("@/assets/pd1.png"),
      require("@/assets/pd2.png"),
      require("@/assets/pd3.png"),
    ],
  },
];
