<template>
  <section
    class="about-us h-max md:mt-14 lg:mt-0 md:mx-4 lg:px-0 md:px-0 mb-4 lg:mb-0 md:mb-0"
  >
    <!-- Set a fixed height here -->
    <div
      class="flex flex-col-reverse lg:flex-row items-stretch justify-between"
    >
      <!-- Left Column -->
      <div
        class="left-col w-full lg:w-1/2 md:p-0 md:py-16 py-4 lg:pr-8 md:pr-0 flex flex-col justify-center overflow-hidden"
      >
        <h2
          class="md:text-4xl text-3xl font-poppins mb-9 font-semibold md:leading-normal md:mb-4 text-black"
        >
          Our Story. <br />Your Journey.
        </h2>
        <p
          class="leading-relaxed text-black font-poppins md:text-base text-xs font-normal"
        >
          Wellness? Oh well… isn't that a complicated word? So we decided to
          make it simple.
          <br /><br />
          We made a promise to ourselves when Wofree was born to give you
          uncomplicated, clean & holistic care through a wellness platform that
          has great products, an impactful community and personalised
          experiences. All we're doing now is keeping our end of the promise.
        </p>
      </div>

      <!-- Right Column -->
      <div
        class="right-col w-full lg:w-1/2 md:w-11/12 flex items-center justify-center h-full"
      >
        <img
          :src="aboutImage"
          alt="About Us Image"
          class="w-full h-full object-cover rounded-xl"
        />
      </div>
    </div>
  </section>
</template>

<script>
import aboutImage from "@/assets/aboutImg.png";

export default {
  name: "AboutUsOne",
  data() {
    return {
      aboutImage,
    };
  },
};
</script>
