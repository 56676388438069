<template>
  <div class="mt-10 md:mt-10 lg:mx-24 md:mx-0 mx-4">
    <AboutUsOne />
    <AboutUsTwo />
    <AboutThree />
    <AboutFour />
  </div>
</template>

<script>
import AboutFour from "./AboutFour.vue";
import AboutThree from "./AboutThree.vue";
import AboutUsOne from "./AboutUsOne.vue";
import AboutUsTwo from "./AboutUsTwo.vue";

export default {
  name: "AboutPage",
  components: {
    AboutUsOne,
    AboutUsTwo,
    AboutThree,
    AboutFour,
  },
};
</script>
