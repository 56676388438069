<template>
  <div
    class="flex flex-col items-center md:my-10 my-5 md:py-10 md:px-8 rounded-xl md:mx-8 lg:mx-24 mx-4 lg:mt-10 md:border md:border-[#28B14C]"
  >
    <div class="flex">
      <h2
        class="text-xl md:text-3xl w-full lg:text-4xl px-4 font-medium text-[#000] md:text-center md:mb-6 mb-5 font-poppins whitespace-nowrap"
      >
        Why Choose Our Ultra Thin Pads?
      </h2>
    </div>

    <div
      class="grid grid-cols-3 w-full sm:grid-cols-3 md:px-0 md:grid-cols-3 border rounded-xl px-4 md:border-none border-[#28B14C] lg:grid-cols-5 gap-8 md:py-0 py-4 lg:px-0"
    >
      <div v-if="isMediumDevice" class="flex flex-col items-center space-y-5">
        <div
          v-for="(icon, index) in icons.slice(0, 2)"
          :key="index"
          class="flex flex-col items-center"
        >
          <img
            :src="icon.img"
            :alt="'Icon ' + (index + 1)"
            :class="{
              'md:w-43 md:h-50 w-20 h-20': true,
            }"
          />
          <!-- <p
            class="text-center hidden md:text-base text-xs font-medium leading-[26.2px] capitalize font-poppins"
            style="color: var(--color-black-solid, #000)"
          >
            {{ icon.label }}
          </p> -->
        </div>
      </div>

      <div v-if="isMediumDevice" class="flex items-center justify-center">
        <div class="flex flex-col items-center">
          <img
            :src="icons[2].img"
            :alt="'Icon ' + 3"
            :class="{
              'lg:w-43 lg:h-50 w-20 h-20': true,
            }"
          />
          <!-- <p
            class="text-center md:text-base text-xs font-medium hidden leading-[26.2px] capitalize font-poppins"
            style="color: var(--color-black-solid, #000)"
          >
            {{ icons[2].label }}
          </p> -->
        </div>
      </div>

      <div v-if="isMediumDevice" class="flex flex-col items-center space-y-5">
        <div
          v-for="(icon, index) in icons.slice(3)"
          :key="index"
          class="flex flex-col items-center"
        >
          <img
            :src="icon.img"
            :alt="'Icon ' + (index + 4)"
            :class="{
              'md:w-43 md:h-50 w-20 h-20': true,
            }"
          />
          <!-- <p
            class="text-center md:text-base hidden text-xs font-medium leading-5 md:leading-[26.2px] capitalize font-poppins"
            style="color: var(--color-black-solid, #000)"
          >
            {{ icon.label }}
          </p> -->
        </div>
      </div>

      <div
        v-else
        v-for="(icon, index) in icons"
        :key="index"
        class="flex flex-col items-center"
      >
        <img
          :src="icon.img"
          :alt="'Icon ' + (index + 1)"
          class="lg:w-44 lg:h-44 w-20 h-20"
        />
        <!-- <p
          class="text-center hidden text-lg font-medium leading-[26.2px] capitalize font-poppins"
          style="color: var(--color-black-solid, #000)"
        >
          {{ icon.label }}
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import expertImage from "@/assets/bannerBg.png";

export default {
  name: "ChooseUs",
  data() {
    return {
      expertImage,
      icons: [
        {
          img: require("@/assets/chooseFive.png"),
          label: "Dermatologically Tested",
        },
        { img: require("@/assets/chooseFour.png"), label: "Wider Back" },
        {
          img: require("@/assets/chooseThree.png"),
          label: "Advance gel technology",
        },
        {
          img: require("@/assets/chooseTwo.png"),
          label: "10 hours of leak protection",
        },
        { img: require("@/assets/chooseOne.png"), label: "Cruelty-free" },
      ],
    };
  },
  computed: {
    isMediumDevice() {
      return window.innerWidth < 1024;
    },
  },
};
</script>
