<template>
  <div class="flex lg:w-7xl">
    <div class="">
      <h2
        class="lg:text-3xl text-lg font-medium leading-normal mb-5 text-left mt-6 md:mt-6 px-8 md:px-0"
      >
        {{ addressHeading }}
      </h2>

      <div
        v-if="hasAddress"
        class="mx-4 md:mx-0 md:mt-0 p-4 md:bg-[#FCFBF7] w-full shadow bg-white radius md:border border-none"
      >
        <!-- Inner card with shadow and white background -->
        <div class="card-container mb-6">
          <div class="flex md:gap-32 gap-0 p-6 md:p-5 outer-border">
            <div class="">
              <h2
                class="lg:text-lg font-bold font-nunito text-sm leading-6 md:mb-4 mb-2"
              >
                {{ address.name }}
              </h2>
              <p
                class="text-[#242424] md:text-sm leading-6 font-normal md:mb-2 mb-3"
              >
                {{ address.street }}
              </p>
              <p
                class="text-[#242424] lg:text-sm text-sm leading-6 mb-3 md:mb-5"
              >
                {{ address.phone }}
              </p>
              <div class="flex md:space-x-6 space-x-4 font-nunito md:my-6">
                <button
                  @click="showEditModal = true"
                  class="md:text-sm font-bold leading-5"
                >
                  Edit
                </button>
                <button
                  @click="removeAddress"
                  class="md:text-sm font-cabin font-bold leading-5"
                >
                  Remove
                </button>
              </div>
            </div>
            <div
              class="flex flex-col md:items-end items-start mt-2 md:mt-0 space-y-7"
            >
              <button
                class="bg-[#28B14C] text-white font-cabin font-semibold px-4 text-sm md:py-2 py-1 rounded-md"
              >
                {{ address.label }}
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center lg:mx-0">
          <button
            @click="showAddAddressModal = true"
            class="px-6 w-full lg:mb-8 mb-7 lg:py-2 py-2 mx-6 md:mx-0 rounded border border-[#28B14C] bg-[#28B14C] text-white font-medium text-lg"
          >
            + Add Address
          </button>
        </div>
      </div>

      <!-- Display 'No Address Available' when hasAddress is false -->
      <div v-else>
        <BlankAddress />
      </div>

      <!-- Edit Address Modal -->
      <EditAddress
        v-if="showEditModal"
        :isVisible="showEditModal"
        @close="showEditModal = false"
      />

      <!-- Add Address Modal -->
      <AddAddress
        v-if="showAddAddressModal"
        :isVisible="showAddAddressModal"
        @close="showAddAddressModal = false"
      />
    </div>
  </div>
</template>

<script>
import BlankAddress from "./BlankAddress.vue";
import EditAddress from "./EditAddress.vue";
import AddAddress from "./AddAddress.vue";

export default {
  components: {
    EditAddress,
    BlankAddress,
    AddAddress,
  },
  data() {
    return {
      showEditModal: false,
      showAddAddressModal: false,
      hasAddress: true, // Initially set to true since an address exists
      address: {
        name: "Priyanshi Agarwal",
        street:
          "106 Kiran Chandra Singha Road, Shibpur Howrah-711102, Kolkata, Bengal",
        phone: "7439892375",
        label: "Home",
      },
    };
  },
  computed: {
    addressHeading() {
      return this.hasAddress ? "Saved Address" : "";
    },
  },
  methods: {
    addNewAddress() {
      this.hasAddress = true;
    },
    addAddress() {
      this.showAddAddressModal = true;
    },
    removeAddress() {
      // Set hasAddress to false to hide the address details and show the BlankAddress component
      this.hasAddress = false;
      this.address = {}; // Clear the address data if necessary
    },
  },
};
</script>

<style>
.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.radius {
  border-radius: 15px 15px 0px 0px;
}

/* Outer grey border */
.outer-border {
  border: 1px solid #d3d3d3; /* Light grey outer border */
  padding: 8px; /* Space between outer border and inner card */
  border-radius: 20px; /* Round corners for outer border */
}

/* Inner card styling */
.card-container {
  border-radius: 15px;
}
</style>
