<template>
  <section id="blog" class="md:my-10 mt-6 md:mt-0 md:mx-8 lg:mx-24 mx-4">
    <div class="max-w-7xl mx-auto">
      <div class="flex justify-between items-center mb-4 lg:mb-10">
        <div class="w-full">
          <h2 class="md:text-4xl text-2xl font-bold text-black font-nunito">
            Our Blogs
          </h2>
          <p
            class="md:text-lg text-xs text-gray-600 md:mt-4 mt-2 leading-5 font-normal md:pr-0"
          >
            Explore insightful articles on women's health, wellness tips, and
            sustainable living. Stay informed with the latest trends and expert
            advice tailored just for you.
          </p>
        </div>
      </div>

      <!-- Auto Slider with Swipe for mobile view -->
      <div
        class="lg:hidden relative overflow-hidden h-fit"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
      >
        <div
          class="flex transition-all duration-500"
          :style="{ transform: `translateX(-${currentSlide * 50}%)` }"
        >
          <div
            v-for="(blog, index) in blogs"
            :key="index"
            class="w-1/2 flex-shrink-0 px-2"
          >
            <BlogCard
              :blog="blog"
              :index="index"
              :title-below-image="true"
              @go-to-detail="goToDetail"
              :showAuthor="true"
              :showDescription="true"
              :showCategory="true"
              backgroundClass="custom-bg"
            />
          </div>
        </div>

        <!-- Slide indicators -->
        <div class="flex justify-center mt-4">
          <span
            v-for="(blog, index) in Math.ceil(blogs.length / 2)"
            :key="index"
            :class="{
              'bg-[#05AEEF]': currentSlide === index,
              'bg-gray-400': currentSlide !== index,
            }"
            class="w-[6px] h-[6px] rounded-full mx-1 cursor-pointer"
            @click="setSlide(index)"
          ></span>
        </div>
      </div>

      <!-- Grid for desktop view -->
      <div
        class="hidden lg:grid grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-8 lg:gap-20 cursor-pointer"
      >
        <BlogCard
          v-for="(blog, index) in blogs.slice(0, 3)"
          :key="index"
          :blog="blog"
          :index="index"
          @go-to-detail="goToDetail"
          backgroundClass="custom-bg"
          :title-below-image="true"
          :showCategory="true"
          :showAuthor="true"
          padding="16px"
        />
      </div>
    </div>
    <div class="flex justify-center lg:mt-14 mt-5">
      <button
        @click="goToAllBlogs"
        class="border-[#05AEEF] border rounded text-[#05AEEF] font-nunito md:text-base text-sm font-medium px-6 py-2"
      >
        Browse More
      </button>
    </div>
  </section>
</template>

<script>
import { blogs } from "./blogData";
import BlogCard from "./BlogCard.vue";

export default {
  components: {
    BlogCard,
  },
  data() {
    return {
      blogs,
      currentSlide: 0,
      slideInterval: null,
      touchStartX: 0,
      touchEndX: 0,
    };
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
  methods: {
    startAutoSlide() {
      this.slideInterval = setInterval(() => {
        this.nextSlide();
      }, 3000);
    },
    stopAutoSlide() {
      clearInterval(this.slideInterval);
    },
    nextSlide() {
      this.currentSlide =
        (this.currentSlide + 1) % Math.ceil(this.blogs.length / 2);
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + Math.ceil(this.blogs.length / 2)) %
        Math.ceil(this.blogs.length / 2);
    },
    setSlide(index) {
      this.currentSlide = index;
    },
    onTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    onTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    onTouchEnd() {
      if (this.touchStartX - this.touchEndX > 50) {
        this.nextSlide();
      } else if (this.touchEndX - this.touchStartX > 50) {
        this.prevSlide();
      }
    },
    goToAllBlogs() {
      this.$router.push({ name: "AllBlogs" });
    },
    goToDetail(index) {
      this.$router.push({ name: "blog-detail", params: { id: index } });
    },
  },
};
</script>

<style scoped>
.custom-bg {
  background: rgba(40, 177, 76, 0.06);
}
</style>
